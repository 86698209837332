<mat-card>
  <mat-card-title *ngIf="scheda.id_scheda">Modifica Scheda {{paziente.nome}} {{paziente.cognome}} {{paziente.data_nascita | date: 'dd/MM/yyyy'}}</mat-card-title>
  <mat-card-title *ngIf="!scheda.id_scheda">Nuova Scheda <span *ngIf="paziente.idscheda">{{paziente.nome}} {{paziente.cognome}} {{paziente.data_nascita | date: 'dd/MM/yyyy'}}</span></mat-card-title>
  <mat-card-content>
      <!--<div flexLayout="row">
          <div fxFlex="20%">
              Seleziona Paziente
          </div>
          <div fxFlex="80%">
              <mat-select [(ngModel)]="paziente.idscheda" name="paziente.idscheda" placeholder="Seleziona Paziente">
                <mat-option  *ngFor="let option of pazienti" [value]="option.idscheda">
                  {{ option.cognome }} {{ option.nome }} - {{ option.data_nascita | date: 'dd/MM/yyyy' }}
                </mat-option>
              </mat-select>
          </div>
      </div>-->
      <div flexLayout="row" *ngIf="stato_update">
        Inserisci i dati di un nuovo paziente
      </div>
      <div flexLayout="row" *ngIf="stato_update">
          <div fxFlex="40%">
              <mat-form-field fxFlexFill>
                  <input matInput placeholder="Cognome" [(ngModel)]="paziente.cognome" name="paziente.cognome">
              </mat-form-field>
          </div>
          <div fxFlex="40%">
              <mat-form-field fxFlexFill>
                  <input matInput placeholder="Nome" [(ngModel)]="paziente.nome" name="paziente.nome">
              </mat-form-field>
          </div>
          <div fxFlex="20%">
              <mat-form-field fxFlexFill>
                  <input matInput type ="date" placeholder="Data di nascita" [(ngModel)]="paziente.data_nascita">
              </mat-form-field>
          </div>
      </div>
      <br/>
      <mat-divider></mat-divider>
      <br/>
    <form #SchedaForm="ngForm" validate>        
      <div flexLayout="row">
        <div fxFlex="100%">
            <mat-form-field fxFlexFill>
                <input matInput placeholder="Diagnosi" required [(ngModel)]="scheda.diagnosi" name="scheda.diagnosi">
            </mat-form-field>
        </div>
      </div>
      <div flexLayout="row">
        <div fxFlex="100%">
            <mat-form-field fxFlexFill>
                <input matInput placeholder="Note Operative per i terapisti" [(ngModel)]="scheda.note_operative" name="scheda.note_operative">
            </mat-form-field>
        </div>
      </div>
      <div flexLayout="row">
          <div fxFlex="20%">
              <mat-select (selectionChange)="AggiungiNote($event)">
                  <mat-option *ngFor="let terapia_ of terapie_asl" [(value)]="terapia_.descrizione_completa">{{terapia_.descrizione}}</mat-option>
                </mat-select>
          </div>
          <div fxFlex="80%">
              <mat-form-field fxFlexFill>
                  <input matInput placeholder="Note per segreteria" [(ngModel)]="scheda.note" name="scheda.note">
              </mat-form-field>
          </div>
      </div>
      <div flexLayout="row" *ngFor="let terapy of scheda.terapie; let i=index; trackBy: trackByIndex">
        <div fxFlex="30%">
          <mat-form-field>
            <mat-select [(ngModel)]="scheda.terapie[i].id_terapia" name="scheda.terapie.id_terapia+{{i}}">
              <mat-option *ngFor="let terapia_ of terapies" [(value)]="terapia_.idterapia">{{terapia_.descrizione}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div fxFlex="30%">
            <mat-form-field>
              <mat-select [(ngModel)]="scheda.terapie[i].id_segmento" name="scheda.terapie.id_segmento+{{i}}">
                <mat-option *ngFor="let segmento of segmenti" [(value)]="segmento.IdSegmento">{{segmento.descrizione}}</mat-option>
              </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex="30%">
            <mat-form-field>
              <mat-select class="mini-select" [(ngModel)]="scheda.terapie[i].quantita_prescritta" name="scheda.terapie.quantita_prescritta+{{i}}">
                  <mat-option value="1">1</mat-option>
                  <mat-option value="2">2</mat-option>
                  <mat-option value="3">3</mat-option>
                  <mat-option value="4">4</mat-option>
                  <mat-option value="5">5</mat-option>
                  <mat-option value="6">6</mat-option>
                  <mat-option value="7">7</mat-option>
                  <mat-option value="8">8</mat-option>
                  <mat-option value="9">9</mat-option>
                  <mat-option value="10">10</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="10%">
              <button mat-mini-fab (click)="Rimuoviterapia(i)"><mat-icon color="warn" >cancel</mat-icon></button>
          </div>
      </div>
    </form>
    <button mat-raised-button color="accent" (click)="aggiungi_riga_terapia()">Aggiungi ulteriore Terapia alla Scheda</button>
    <button mat-raised-button color="accent" *ngIf="scheda.id_scheda" (click)="nuova_scheda()">Nuova Scheda per lo stesso Paziente</button>
    <br/><br/>
    <button mat-fab color="primary" (click)="SaveForm()" class="bottom-right" [disabled]="!SchedaForm.valid"> 
        Salva
    </button>
  </mat-card-content>
</mat-card>
