<mat-card>
  <mat-card-title>{{array_holter.length}} Holter trovati</mat-card-title>
  <mat-card-content class="push-bottom-none">
    <div fxLayout="row" fxLayout.xs="column">
        <div fxFlex="15%">
            <mat-form-field>
                <input matInput type="date" placeholder="Data inizio selezione" [(ngModel)]="temp.data_in">
              </mat-form-field>
          </div>
          <div fxFlex="15%">
              <mat-form-field>
                  <input matInput type="date" placeholder="Data fine selezione" [(ngModel)]="temp.data_out">
              </mat-form-field>
          </div>
          <div fxFlex="10%">
              <button mat-button color="primary" (click)="Cerca()">FILTRA</button>
          </div>
      <div fxFlex="60%"></div>
    </div>
    <div fxLayout="row" fx-layout-align="start center">
        <mat-form-field appearance="standard" fxFlexFill>
            <mat-label>Filtra i pazienti scrivendo cognome e nome senza spazio tra l'uno e l'altro</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Scrivi qui il cognome e nome senza spazio tra l'uno e l'altro" #input>
          </mat-form-field>
      </div>
          <mat-divider></mat-divider>

          <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8" matSort>
              
            <!-- Cognome Column -->
            <ng-container matColumnDef="cognome">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Cognome </th>
              <td mat-cell *matCellDef="let element"> {{element.cognome}} </td>
            </ng-container>
          
            <!-- Nome Column -->
            <ng-container matColumnDef="nome">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
              <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
            </ng-container>
          
            <!-- Data di Nascita Column -->
            <ng-container matColumnDef="data_nascita">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Data di Nascita </th>
              <td mat-cell *matCellDef="let element"> {{element.data_nascita | date:'dd/MM/yyyy'}} </td>
            </ng-container>

            <!-- Data Montaggio Column -->
            <ng-container matColumnDef="data_montaggio">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Data Montaggio </th>
                <td mat-cell *matCellDef="let element"> {{element.data_montaggio | date:'dd/MM/yyyy'}} </td>
              </ng-container>

              <!-- Tipo -->
            <ng-container matColumnDef="tipo">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo </th>
                <td mat-cell *matCellDef="let element"><span *ngIf="element.tipo==0">Holter ECG</span>
                    <span *ngIf="element.tipo==1">Holter Pressorio</span>
                    <span *ngIf="element.tipo==2">Polisonnigrafo</span></td>
              </ng-container>

              <!-- Serial Number -->
            <ng-container matColumnDef="serial_number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> SN </th>
                <td mat-cell *matCellDef="let element"> {{element.serial_number}} </td>
              </ng-container>

              <!-- ASL Column -->
            <ng-container matColumnDef="asl">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Pagamento? </th>
                <td mat-cell *matCellDef="let element"><span *ngIf="element.asl==0">Si</span>
                    <span *ngIf="element.asl==1">No</span> </td>
              </ng-container>

              <!-- Stato Column -->
            <ng-container matColumnDef="stato">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Ripetuto? </th>
                <td mat-cell *matCellDef="let element"> 
                    <span *ngIf="element.stato==0">No</span>
              <span *ngIf="element.stato==1">Si</span>
                </td>
              </ng-container>

              <!-- Password Column -->
            <ng-container matColumnDef="password">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Password PDF </th>
                <td mat-cell *matCellDef="let element"><b style="font-family: 'Times New Roman', Times, serif;">{{element.password}}<button mat-icon-button (click)="copyMessage(element.password)"><mat-icon>content_copy</mat-icon></button></b></td>
              </ng-container>

            <!-- Azioni -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>  </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button [mat-menu-trigger-for]="menu">
                    <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu x-position="before" #menu="matMenu">
                        <button (click)="Elimina(element)" mat-menu-item><mat-icon mat-list-icon>delete</mat-icon>Elimina</button>
                  <button (click)="Ripeti(element)" mat-menu-item><mat-icon mat-list-icon>edit</mat-icon>Ripeti</button>
                  <button (click)="service.print('Holter','',today,element,'','','Cardiologia',1,cdcp)" mat-menu-item><mat-icon mat-list-icon>refresh</mat-icon>Ristampa</button>
                    </mat-menu>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

              <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                  class="example-element-row">
              </tr>
            
              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
              </tr>

            </table>
            <mat-paginator [pageSizeOptions]="[5, 20, 50, 100]" aria-label="Select page of users"></mat-paginator>
   <!--     <td-data-table
          #dataTable
          [data]="filteredData"
          [columns]="columns"
          [sortable]="true"
          [sortBy]="sortBy"
          [sortOrder]="sortOrder"
          (sortChange)="sort($event)"
          >

          <ng-template tdDataTableTemplate="data_montaggio" let-value="data_montaggio" let-row="row">
              {{row.data_montaggio | date: 'dd/MM/yyyy'}}
          </ng-template>
          <ng-template tdDataTableTemplate="data_nascita" let-value="data_nascita" let-row="row">
            {{row.data_nascita | date: 'dd/MM/yyyy'}}
          </ng-template>
          <ng-template tdDataTableTemplate="stato" let-value="stato" let-row="row">
              <span *ngIf="row.stato==0">No</span>
              <span *ngIf="row.stato==1">Si</span>
            </ng-template>
            <ng-template tdDataTableTemplate="asl" let-value="asl" let-row="row">
                <span *ngIf="row.asl==0">Si</span>
                <span *ngIf="row.asl==1">No</span>
              </ng-template>
              <ng-template tdDataTableTemplate="tipo" let-value="tipo" let-row="row">
                  <span *ngIf="row.tipo==0">Holter ECG</span>
                  <span *ngIf="row.tipo==1">Holter Pressorio</span>
                </ng-template>
                <ng-template tdDataTableTemplate="password" let-value="password" let-row="row">
                    <b style="font-family: 'Times New Roman', Times, serif;">{{row.password}}</b>
                  </ng-template>
          <ng-template tdDataTableTemplate="actions" let-value="id" let-row="row">
              <button mat-icon-button [mat-menu-trigger-for]="menu">
              <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu x-position="before" #menu="matMenu">
                    <button (click)="Elimina(row)" mat-menu-item><mat-icon mat-list-icon>delete</mat-icon>Elimina</button>
                  <button (click)="Ripeti(row)" mat-menu-item><mat-icon mat-list-icon>edit</mat-icon>Ripeti</button>
                  <button (click)="service.print('Holter','',today,row,'','','Cardiologia',1,cdcp)" mat-menu-item><mat-icon mat-list-icon>refresh</mat-icon>Ristampa</button>
              </mat-menu>
          </ng-template>

        </td-data-table>
        <div class="mat-padding" *ngIf="!dataTable.hasData" fxLayout="row" layout-align="center center">
            <h3>Nessun risultato da mostrare.</h3>
        </div>
        <div fxLayout="row">
            <td-paging-bar #pagingBar [pageSize]="pageSize" [total]="filteredTotal" (change)="page($event)">
            <div fxLayout="row">
                <div fxFlex="33%" fxHide.xs='true'>
                    Risultati per pagina:
                </div>
                <div fxFlex="33%" fxHide.xs='true'>
                    <mat-select [style.width.px]="50" [(ngModel)]="pageSize">
                    <mat-option *ngFor="let size of [8,16,40,100]" [value]="size">
                        {{size}}
                    </mat-option>
                    </mat-select>
                </div>
                <div fxFlex="34%" fxFlex.xs="100%">
                    {{pagingBar.range}} <span hide-xs>di {{pagingBar.total}}</span>
                </div>
            </div>
            </td-paging-bar>
      </div>
    </div>-->
    <div flexLayout="row">
        <h3>Inserisci nuovo</h3>
        <div fxLayout="row" fxLayout.xs='column' fxLayoutGap="5px">
            <div fxFlex="15%">
                <mat-form-field fxFlexFill>
                        <input matInput placeholder="Cognome" [(ngModel)]="holter.cognome" name="holter.cognome">
                </mat-form-field>
            </div>
            <div fxFlex="15%">
                <mat-form-field fxFlexFill>
                        <input matInput placeholder="Nome" [(ngModel)]="holter.nome" name="holter.nome">
                </mat-form-field>
            </div>
            <div fxFlex="10%">
                <mat-form-field fxFlexFill>
                    <input matInput type="date" placeholder="Data di nascita" [(ngModel)]="holter.data_nascita">
                </mat-form-field>
            </div>
            <div fxFlex="10%">
                <mat-form-field fxFlexFill>
                        <mat-select (selectionChange)="Apparecchi()" matInput placeholder="Tipo" [(ngModel)]="holter.tipo" name="holter.tipo">
                        <mat-option value="0">Holter ECG</mat-option>
                        <mat-option value="1">Holter Pressorio</mat-option>
                        <mat-option value="2">Polisonnigrafo</mat-option>
                        </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="5%">
                <mat-form-field fxFlexFill>
                    <mat-select matInput placeholder="Pagamento?" [(ngModel)]="holter.asl" name="holter.asl">
                        <mat-option value="0">Si</mat-option>
                        <mat-option value="1">No</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxFlex="10%">
                    <mat-form-field fxFlexFill>
                            <mat-select matInput placeholder="Numero Seriale" [(ngModel)]="holter.serial_number" name="holter.serial_number">
                            <mat-option *ngFor="let apparecchio of apparecchi" value="{{apparecchio.serial_number}}">{{apparecchio.serial_number}}</mat-option>
                            </mat-select>
                    </mat-form-field>
            </div>
            <div fxFlex="10%">
              <mat-form-field fxFlexFill>
                      <mat-select matInput placeholder="Operatore" [(ngModel)]="holter.operatore" name="holter.operatore">
                      <mat-option *ngFor="let infermiere of infermieri" value="{{infermiere.nominativo}}">{{infermiere.username}}</mat-option>
                      </mat-select>
              </mat-form-field>
            </div>
            <div fxFlex="5%">
                <button mat-raised-button color="primary" (click)="Aggiungi()">Aggiungi</button>
            </div>
        </div>
    </div>
    <div flexLayout="row">
      <div fxLayout="row" fxLayout.xs='column' fxLayoutGap="5px">
        
        <div fxFlex="30%">
            <mat-form-field fxFlexFill>
                    <input matInput placeholder="Ricerca per Codice Fiscale" [(ngModel)]="cf" name="cf" (ngModelChange)="EstraiData($event)">
            </mat-form-field>
        </div>
        </div>
    </div>
  </mat-card-content>
</mat-card>