import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpInterceptor, HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';
import moment from 'moment';
import { de, el } from 'date-fns/locale';
import { formatDate } from "@angular/common";
import htmlToPdfmake from 'html-to-pdfmake';
import { ElencoPazientiComponent } from '../archivio-pazienti/elenco-pazienti/elenco-pazienti.component';
import { pricktest, PRPN } from '../_models';
import { templateJitUrl } from '@angular/compiler';
(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;
pdfMake.vfs["Times-New-Roman-Regular.ttf"] = "BASE 64 HERE";
pdfMake.vfs["Times-New-Roman-Medium.ttf"] = "BASE 64 HERE";
pdfMake.vfs["Times-New-Roman-Italic.ttf"] = "BASE 64 HERE";
pdfMake.fonts = {
    // Default font should still be available
    Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-Italic.ttf'
    },
    // Make sure you define all 4 components - normal, bold, italics, bolditalics - (even if they all point to the same font file)
    TimesNewRoman: {
        normal: 'Times-New-Roman-Regular.ttf',
        bold: 'Times-New-Roman-Bold.ttf',
        italics: 'Times-New-Roman-Italics.ttf',
        bolditalics: 'Times-New-Roman-Italics.ttf'
    }
};




@Injectable() export class GlobalServices {

		// Dichiarazioni
		jwtHelper: JwtHelperService = new JwtHelperService();
		

		// Elenco Endopint API
		private baseURL='https://www.studio.medicina2000bari.it/APIv2';
		private baseResURL='https://www.studio.medicina2000bari.it/res/';
		private intestazione;

		
		public apiGetUsername=this.baseURL+'/login/utenti';
		public apiGetTipiVisita=this.baseURL+'/tipi_visita';				
		public apiGetIngresso=this.baseURL+'/ingresso';
		public apiHolter=this.baseURL+'/riepilogo-holter/';
		public apiPlantari=this.baseURL+'/riepilogo-plantari/';
		public apiPolisonno=this.baseURL+'/riepilogo-polisonno/';
		public apiModHolter=this.baseURL+'/mod-holter/';
		public apiModPlantare=this.baseURL+'/mod-plantare/';
		public apiModPolisonno=this.baseURL+'/mod-polisonno/';
		public apiDeleteHolter=this.baseURL+'/delete-holter/';
		public apiDeletePlantare=this.baseURL+'/delete-plantare/';
		public apiDeletePolisonno=this.baseURL+'/delete-polisonno/';
		public apigetApparecchi=this.baseURL+'/apparecchi/';
		public apiTimbra=this.baseURL+'/timbra/';
		public apiGetRiepilogo= this.baseURL+'/riepilogo-presenze/';
		public apiGetRiepilogoPrestazioni= this.baseURL+'/riepilogo-prestazioni/';
		public apiGetUtenti= this.baseURL + '/utenti';
		public apiGetPazienti= this.baseURL + '/pazienti/';
		public apiGetPazientiSchede= this.baseURL + '/pazienti/schede';
		public apiDeletePaziente= this.baseURL + '/paziente/cancella/';
		public apiGetPazientifromScheda= this.baseURL + '/paziente-scheda/';
		public apiGetMeta= this.baseURL + '/meta/';
		public apiPostMeta= this.baseURL + '/save_meta/';
		public apiPostMetaPazienti= this.baseURL + '/save_meta_pazienti/';
		public apiGetSchede= this.baseURL + '/schede/';
		public apiGetScheda= this.baseURL + '/scheda/';
		public apiUpdateScheda=this.baseURL+'/scheda/';
		public apiRimuoviTerapiaScheda=this.baseURL+'/scheda/rimuovi-terapia/';
		public apiTerapia=this.baseURL+'/terapia/';
		public apiTerapiaForzata=this.baseURL+'/terapia-forzata/';
		public apiGetTerapie=this.baseURL+'/get-terapie/';
		public apiGetSegmenti=this.baseURL+'/get-segmenti/';
		public apiDeleteTerapia=this.baseURL+'/elimina-terapia/';
		public apiAbilitaTerapia=this.baseURL+'/abilita-terapia/';
		public apiGetUtente= this.baseURL + '/utente';
		public apiUpdateUtenti=this.baseURL + '/utente/';
		public apiGetTipo=this.baseURL+'/tipo';
		public apiGetProfilo= this.baseURL + '/profilo';
		public apiUpdateProfilo=this.baseURL + '/profilo';
		public apiUpdatePazienti=this.baseURL + '/pazienti/';
		public apiGetVisite= this.baseURL + '/visite/';
		public apiPostVisite= this.baseURL + '/visite/';
		public apiGetAnamnesi= this.baseURL + '/anamnesi/';
		public apiGetTipiVisite= this.baseURL + '/tipi-visite/';
		public apiPostAnamnesi= this.baseURL + '/anamnesi/';
		public apiGetNoteDirettore= this.baseURL+'/note_direttore/';
		public apiGetTerapieOggi= this.baseURL+'/terapie-eseguite/';
		public apiGetTerapieEseguite= this.baseURL+'/terapie-eseguite/';
		public apiGetTerapieEseguitePrecedenti= this.baseURL+'/terapie-eseguite-precedenti/';
		public apiGetTerapieEseguiteOperatore= this.baseURL+'/terapie-eseguite-singolo/';
		public apiDeleteNota= this.baseURL+'/elimina-nota/';
		public apiUpdateNoteDirettore= this.baseURL+'/note_direttore/';
		public apiGetImpostazioni= this.baseURL+'/impostazioni/';
		public apiGetNominativo= this.baseURL+'/nominativo/';
		public apiUpdateImpostazioni= this.baseURL+'/impostazioni/';
		public apiOrdine= this.baseURL+'/ordine/';
		public apiPostPrestazionePrivata=this.baseURL+'/prestazione_privata';
		public apiPostSegnalazioni=this.baseURL+'/segnalazioni/';
		public apiGetTerapiedaEseguire=	this.baseURL+'/schede/terapie_bacheca';

		
		// Funzioni
		constructor (public http: HttpClient) {
			
		}

		post_function(json_pass: string, url_api: string){
            return this.http.post(url_api, json_pass)
            .pipe(map( (response:any) => 
                {
                return response.data
                }
                ))
		}

		post_function_bis(json_pass: string, url_api: string){
            return this.http.post(url_api, json_pass)
            .pipe(map( (response:any) => 
                {
                return response
                }
                ))
		}

		put_function(json_pass: string, url_api: string){
			return this.http.put(url_api, json_pass).pipe(map( response => 
                {
                return response
                }
                ))		
		}
		
		get_function(url_api:string){
			return this.http.get(url_api).pipe(map( (response: any) => 
                {
                return response.data
                }
                ))
		}

		delete_function(url_api: string){
			return this.http.delete(url_api).pipe(map( response => 
                {
                return response
                }
                ))
			/*.catch((e) => { });*/
		}

		get_access_level()
		{
			let token=sessionStorage.getItem('token');
			if(token) return this.jwtHelper.decodeToken(token).userdata['access_level'];
			else return 0;
		}

		get_username()
		{
			let token=sessionStorage.getItem('token');
			if(token) return this.jwtHelper.decodeToken(token).userdata['username'];
			else return '';
		}

		get_date(element){
			let x= new Date(element);
			return x.toLocaleDateString('it-IT',{year: 'numeric', month: 'long', day: 'numeric'});

		}

		get_data_di_nascita(cf: string){
			let d=parseInt(cf.substr(9,2));
			let y=parseInt(cf.substr(6,2));
			let m=cf.substr(8,1).toUpperCase();
			var me;
			var ye;
			var year=parseInt(new Date().getFullYear().toString().substr(2,2));
			if(y>=year) ye='19'+y.toString();
			else 
			{
				if(y<10) ye='200'+y.toString();
				else ye='20'+y.toString(); 
			}

			if(d>40) d-=40;
			switch(m){
				case 'A':
					me='01';
					break;
				case 'B':
					me='02';
					break;
				case 'C':
					me='03';
					break;
				case 'D':
					me='04';
					break;
				case 'E':
					me='05';
					break;
				case 'H':
					me='06';
					break;
				case 'L':
					me='07';
					break;
				case 'M':
					me='08';
					break;
				case 'P':
					me='09';
					break;
				case 'R':
					me='10';
					break;
				case 'S':
					me='11';
					break;
				case 'T':
					me='12';
					break;
			}
			var data=ye+'/'+me+'/'+d.toString();
			var date= new Date(data);
			return formatDate(date,'yyyy-MM-dd','it-IT');
		}

		OpenFile(obj){
			const base64 =obj.obj;
			const fileName = obj.nome;
			const fileBlob = this.dataURItoBlob(base64,fileName);
			const FFile = new File([fileBlob], fileName, { type: obj.tipo });
			const url= window.URL.createObjectURL(FFile);
			window.open(url);
			}

		dataURItoBlob(dataURI,tipo) {
			const byteString = window.atob(dataURI);
			const arrayBuffer = new ArrayBuffer(byteString.length);
			const int8Array = new Uint8Array(arrayBuffer);
			for (let i = 0; i < byteString.length; i++) {
			  int8Array[i] = byteString.charCodeAt(i);
			}
			const blob = new Blob([int8Array], { type: tipo });    
			return blob;
		 }

		
		checkContent(x){
			if(x) return x;
			else return '';
		}

		getFirma(signature){
			let firma={
				image: signature,
				width: 300,
				alignment: 'right'
			};

			if(signature!='') return firma;
			else return {text:'Non ancora apposta', alignment:'right', italics: true, fontSize: 10};
		}

		print(tipo,obj,today,paziente,nominativo,anamnesi,branca,stampa,imp){
			var today10=new Date(today);
			today10.setDate(today10.getDate()+10);
			var firma=[{text: 'Bari, '+this.formatDate(today, 'data'), margin: [10,30,10,5]},
			{
				columns:[
					{text:'',width:'*'},
					{
						table:{
							body:[
								[{text:nominativo}],
								[{text: 'Art.3 Decreto Legge 39 del 12/02/1993'}]
							]
						},
						layout: 'noBorders',
						alignment: 'center',
						width:'auto'
					}
				],
				margin:[10,5,10,10]
			}];
			var firma_med_interna=[{text: 'Bari, '+this.formatDate(today, 'data'), margin: [10,30,10,5]},
			{
				columns:[
					{text:'',width:'*'},
					{
						table:{
							body:[
								[{text:nominativo}],
								[{text:'Specialista in Medicina Interna'}],
								[{text: 'Art.3 Decreto Legge 39 del 12/02/1993'}]
								
							]
						},
						layout: 'noBorders',
						alignment: 'center',
						width:'auto'
					}
				],
				margin:[10,5,10,10]
			}];

			var int_paziente=
			{columns: [
				{ width: '*', text: '' },
				{
				table:{
					body:[
						
							[{text: 'Paziente: '},{text: paziente.cognome+' '+paziente.nome, bold: true},{text: ' nato/a il '},{text: this.formatDate(paziente.data_nascita, 'data'), bold: true}]
						
					]
				},
				layout: 'noBorders',
				width: 'auto',
				fontSize: 13
			},
			{ width: '*', text: '' }
			],
			margin: [10,0,10,10]
			};


			if(obj) var int_paziente_eta=
			{columns: [
				{ width: '*', text: '' },
				{
				table:{
					body:[
						
							[{text: 'Paziente: '},{text: paziente.cognome+' '+paziente.nome, bold: true},{text: ' nato/a il '},{text: this.formatDate(paziente.data_nascita, 'data'), bold: true},{text: ' ('+obj.eta+' anni)'}]
						
					]
				},
				layout: 'noBorders',
				width: 'auto',
				fontSize: 13
			},
			{ width: '*', text: '' }
			],
			margin: [10,0,10,10]
			};

			var titolo;
			if(paziente.cognome) titolo=paziente.cognome+" "+paziente.nome+" - "+ tipo +" "+ this.formatDate(today, 'data');
			else titolo="Stampa G.M. Sanità";
			var intestazione;
			var intbase64;
			var docDefinition
			switch(branca){
				case 'Fisiatria': intestazione='../../assets/intestazionecs.png'; break;
				case 'Pneumologia': intestazione='../../assets/intestazionem2.png'; break;
				case 'Cardiologia': intestazione='../../assets/intestazionecdcp.png'; break;
				case '': intestazione='../../assets/image832.png'; break;
			}

			const toDataURL = url => fetch(url)
			.then(response => response.blob())
			.then(blob => new Promise((resolve, reject) => {
				const reader = new FileReader()
				reader.onloadend = () => resolve(reader.result)
				reader.onerror = reject
				reader.readAsDataURL(blob)
			}))
			toDataURL(intestazione)
			.then(dataUrl => {
				intbase64=dataUrl;
			 docDefinition={
				info:{
					title: titolo,
					author:'G.M. Sanità',
					creator:'G.M. Sanità',
					producer:'G.M. Sanità'
				},
				pageSize: 'A4',
				pageMargins: [25, 115, 25, 40],
				defaultStyle:{
					fontSize: 11,
					margin: [10,0]
				},
				styles:{
					header:{
						fontSize: 14,
						bold: true,
						margin:[10,5,10,5]
						//background: '#0070c0',
					},

					header1:{
						fontSize: 18,
						bold: true,
						color: '#ffc000',
					},
					header2:{
						fontSize: 18,
						bold: true,
						//background: '#0070c0',
						alignment: 'center',
						characterSpacing: 1.5
					},
					footer:{
						fontSize: 9,
						italics: true
					},
					right:{
						alignment: 'right'
					},
					boldHeader:{
						bold:true,
						fontSize:12
					}
				},
				footer:
					function(currentPage, pageCount) {
						var columns
						if(pageCount>1) columns= [
							{
								text: 'Pag. ' + currentPage.toString() + ' di ' + pageCount,
								style: 'footer',
								margin:[0,0,15,0],
								alignment: 'right'
							}
						];
						else columns=[];
						return  columns;
					},
				header:{
						image: intbase64,
						width: 550,
						margin:[20,15,0,10],
						alignment: 'center'
						},
				content:[]
			}

			var def;
			const data=new Date();
			const data_stampa=data.toLocaleTimeString();
			var matt_pome;
			if(data.getHours()<14) matt_pome="mattutine";
			else matt_pome="pomeridiane";

			switch(tipo){
				case 'Editor':
					docDefinition.content= htmlToPdfmake(obj);
					break;
				case 'intestazioni':
					docDefinition.content=[
					];
					break;
				case 'Presenza_FKT':
				def=[];
				def.push({text:'ATTESTAZIONE',alignment: 'center',bold: true, fontSize: 14,margin:[10,30]});
				def.push(
					{text: 'Si attesta che il paziente '+paziente.cognome+' '+paziente.nome+ ' nato/a il '+this.formatDate(paziente.data_nascita, 'data')+' ha eseguito il trattamento di Fisiokinesiterapia prescritto nelle ore '+matt_pome+'.', margin: [10,0]},
					{text: 'Si rilascia in carta semplice, a richiesta dell\'interessato, per gli usi consentiti dalla Legge.', margin: [10,0]},
					{text: 'Bari, '+this.formatDate(today, 'data'), margin: [10,20,10,10]},
					{
						columns:[
							{text:'',width:'*'},
							{
								table:{
									body:[
										[{text:''}],
										[{text: 'RESPONSABILE STRUTTURA'}]
									]
								},
								layout: 'noBorders',
								alignment: 'center',
								width:'auto'
							}
						],
						margin:[10,10,10,10]
					}
				);
				docDefinition.content=def;
				break;
				case 'Presenza':
					def=[];
					def.push({text:'ATTESTAZIONE',alignment: 'center',bold: true, fontSize: 14,margin:[10,30]});
					def.push(
						{text: 'Si attesta che il paziente '+paziente.cognome+' '+paziente.nome+ ' nato/a il '+this.formatDate(paziente.data_nascita, 'data')+' ha eseguito la visita specialistica prescritta in data odierna accedendo alla struttura nelle ore '+matt_pome+'.', margin: [10,0]},
						{text: 'Si rilascia in carta semplice, a richiesta dell\'interessato, per gli usi consentiti dalla Legge.', margin: [10,0]},
						{text: 'Bari, '+this.formatDate(today, 'data'), margin: [10,20,10,10]},
						{
							columns:[
								{text:'',width:'*'},
								{
									table:{
										body:[
											[{text:''}],
											[{text: 'RESPONSABILE STRUTTURA'}]
										]
									},
									layout: 'noBorders',
									alignment: 'center',
									width:'auto'
								}
							],
							margin:[10,10,10,10]
						}
					);
					docDefinition.content=def;
					break;
				case 'TOTALE_PRESTAZIONI_CARDIOLOGIA':
				case 'TOTALE_PRESTAZIONI_PNEUMOLOGIA':
				case 'TOTALE_PRESTAZIONI_FISIATRIA':
				case 'totale_orario':
						docDefinition.content= htmlToPdfmake(document.getElementById(tipo).innerHTML);
						break;
				case 'AttestazionePresenze':
					def=`<br/><br/><br/><br/>            
					<h2 style="text-align:center">ATTESTAZIONE</h2><br/><br/><br/>
					Si attesta che il/la Sig.`+paziente.nome+`, nato/a a `+paziente.luogo_nascita+` il `+paziente.data_nascita+`, ha eseguito `+paziente.prestazione+` nelle ore `+paziente.ore+` della giornata di oggi.`;
					if(paziente.accompagnatore) def+=`<br/>Il/la paziente è accompagnato da `+paziente.accompagnatore+` identificato a mezzo `+paziente.tipo_documento+` nr. `+paziente.nr_documento+` .`;
					def+=`<br/>
					Si rilascia in carta semplice, a richiesta dell'interessato/a, per gli usi consentiti dalla Legge.<br/><br/>
					<br/><br/>
					<div>
						Bari, `+this.formatDate(today,'data')+`
						<div style="float: right; text-align: right">
							RESPONSABILE STRUTTURA
						</div>
					</div>`;
					docDefinition.content=htmlToPdfmake(def);
				break;
				case 'Plantare':
					def=`<br/>
						<p> Con il presente documento si attesta l'avvenuta richiesta di ordine di plantari per il sig./a `+paziente.nome+` `+paziente.cognome+` nato/a il `+this.formatDate(paziente.data_nascita, 'data')+` che dichiara di calzare `+paziente.scarpa+` .
						</p><p>Come conferma dell'avvenuto ordine il/la paziente`;
						if(paziente.acconto=='187.2') def+=` ha pagato la cifra di 180€ oltre ad IVA, regolarmente fatturati.`;
						else def+=` consegna un acconto di € `+paziente.acconto+` che saranno fatturati insieme al saldo`;
						def+=`<p>Per le comunicazioni inerenti tutte le fasi dell'ordine(prenotazione, produzione, ritiro) il paziente comunica il seguente recapito telefonico: `+paziente.telefono+`</p>
						<p>Bari,`+this.formatDate(today,'data')+`
						<p style="text-align: center">----------------------------------------------------------------</p><br/>`
						var part1=htmlToPdfmake(def);
						def=`<br/><p> Con il presente documento si attesta l'avvenuta richiesta di ordine di plantari per il sig./a `+paziente.nome+` `+paziente.cognome+` nato/a il `+this.formatDate(paziente.data_nascita, 'data')+` che dichiara di calzare `+paziente.scarpa+` .
						</p><p>Come conferma dell'avvenuto ordine il/la paziente`;
						if(paziente.acconto=='187.2') def+=` ha pagato la cifra di 180€ oltre ad IVA, regolarmente fatturati.`;
						else def+=` consegna un acconto di € `+paziente.acconto+` che saranno fatturati insieme al saldo`;
						def+=`<p>Per le comunicazioni inerenti tutte le fasi dell'ordine(prenotazione, produzione, ritiro) il paziente comunica il seguente recapito telefonico: `+paziente.telefono+`</p>
						<p>Bari,`+this.formatDate(today,'data')+`<br/>Firma del paziente o di chi ne fa le veci:_______________________________ </p>`;
						def+=`<h3 style="text-align: center">Ritiro</h3>
						<p>Sottoscrivendo il presente modulo il paziente, o un suo delegato, dichiara di aver ritirato il prodotto in data odierna unitamente alla fattura dell'importo complessivo e della certificazione di conformità del prodotto.</p>
						<p>Bari,________________ <br/>Firma del paziente o di chi ne fa le veci:_________________________________</p><br/>`;
						var part2=htmlToPdfmake(def)
						docDefinition.content.push(part1,{image:intbase64,
							width: 550,
							alignment: 'center' },part2);
						break;

						case 'IstruzioniPlantare':
						def=`<br/><br/>        
						<h3 style="text-align:center">ISTRUZIONI PER L'UTILIZZO DEL PLANTARE PROPRIOCETTIVO</h3>
						<div style="font-size: 14px">
						<div style="text-align: center; font-style: italic">
						  Complimenti per aver scelto questo tipo di soletta: è questo "IL" plantare e non "UN" plantare adatto a Voi!</div><br/>
						  Trattandosi di un dispositivo progettato e realizzato secondo le più moderne tecnologie attualmente a disposizione dell'ingegneria biomedica, ci permettiamo di suggerire alcuni piccoli accorgimenti che ne potranno prolungare la vita e che Vi aiuteranno a conoscerlo meglio:
						  <h3 style="text-align:center">INDICAZIONI</h3>
						  Prevenire e/o correggere e/o compensare le patologie del piede, del camminamento, della postura e della stabilità del V/s. corpo;
						  <h3 style="text-align:center">MODALITA' D'USO</h3>
						  Inserire il dispositivo in una calzatura comoda facendo attenzione a non invertire lato destro con il sinistro: non applicare a scarpe con tacco alto;
						  <h3 style="text-align:center">AVVERTENZE</h3>
						  Il dispositivo è destinato all'uso esclusivo per il quale è stato realizzato;<br/>
						  Si consiglia di non calzare il dispositivo a contatto diretto con la cute;<br/>
						  Qualsiasi variazione somatica dell'utilizzatore può compromettere l'efficacia terapeutica;
						  <h3 style="text-align:center">EFFETTI COLLATERALI</h3>
						  Il dispositivo è generalmente ben tollerato; tuttavia, nel primo periodo d'uso si possono verificare disturbi transitori di assestamento;
						  <h3 style="text-align:center">CAUTELE D'USO</h3>
						  Non esporre a fonti di calore diretto e umidità;<br/>
						  Controllare periodicamente l'aspetto del dispositivo e verificarne la non deformazione;
						  <h3 style="text-align:center">MANUTENZIONE</h3>
						  Rimuovere quotidianamente il plantare dalla calzatura, al fine di consentirne l'asciugatura;<br/>
						  Pulire periodicamente, secondo la necessità, la superficie con un batuffolo di ovatta appena inumidito e con sapone neutro; lasciare asciugare all'aria.<br/><br/>
						  Vi ricordiamo, infine, che, con il tempo e l'uso, il plantare tende a consumarsi e a "scaricarsi", per cui è necessario rinnovarlo: con queste semplici regole il V/s. benessere fisico migliorerà!
						</div>`;
						docDefinition.content=htmlToPdfmake(def);
					break;

				case 'AttestazioneInail':
					def=[
						{text: 'DIARIO PRESENZE', style:'header2',margin:[10,30,10,10]},
						{text: 'PAZIENTE: ______________________________________________________________',alignment:'center',fontSize: '9',margin:[10,30,10,10]},
						{
							margin:[10,30,10,10],
							table:
							{					
							headerRows: 1,
							widths: [ '*', 30,30,30,30,30,30,30,30,30,30 ],
							heights:[20,20,20,20,20,20],
							body: [
							[ {text: 'TERAPIA', bold: true, alignment: 'center' }, '1', '2', '3','4','5','6','7','8','9','10' ],
							[ '','','','','','','','','','',''],
							[ '','','','','','','','','','',''],
							[ '','','','','','','','','','',''],
							[ '','','','','','','','','','',''],
							[ '','','','','','','','','','',''],
							]}
						},
						{text:'Bari, ',margin:[10,30,0,0]},
						{text: 'Firma Paziente ____________________________',margin:[10,40,0,0]},
						{text: 'RESPONSABILE STRUTTURA', alignment:'right',margin:[10,10,10,10]},
						{text: '___________________________', alignment:'right',margin:[10,40,10,10]}
					];
					docDefinition.content=def;
					break;
				case 'Holter':
					def=[
						{text:'\u200B       Il/La sottoscritto/a '+paziente.nome+' '+paziente.cognome+' nato/a il '+this.formatDate(paziente.data_nascita, 'data')+' dichiara che gli è stata montata in data odierna l\'apparecchiatura per il monitoraggio cardiovascolare e/o del sonno avente numero di matricola '+paziente.serial_number+', applicato dall\'operatore '+paziente.operatore+', di cui viene contemporaneamente nominato/a custode, e che si impegna a restituire nelle stesse condizioni con cui l\'ha ricevuta, assumendosene la piena responsabilità in caso di danneggiamento o furto.', margin: [10,10,10,0]}
					];
					def.push(
						
						{text: 'Il/La sottoscritto/a è stato/a informato/a che: ', margin: [20,0,10,0]},
						{ol:[
							'L\'apparecchio NON deve ricevere TRAUMI;',
							'L\'apparecchio NON deve essere esposto ad UMIDITA\', ACQUA, LIQUIDI in genere;',
							'Sia il registratore che tutte le applicazioni (elettrodi, bracciali, tubi, ecc...) NON devono essere maneggiati e spostati dalle loro posizioni;',
							'L\'apparecchio gli viene consegnato solo dopo la sua applicazione e la verifica del perfetto funzionamento: pertanto la presenza di artefatti e/o la mancanza totale o parziale di registrazione non può essere imputabile al Centro Diagnostico Cardiopolmonare s.r.l. e non gli dà diritto a qualunque azione di rivalsa, ivi compresa la ripetizione dell\'esame;',
							' ',
							{ul:[
								'[__] Ritirerà il referto di persona o tramite suo incaricato',
								'[__] Desidera ricevere il referto via MAIL, al seguente indirizzo da scrivere in STAMPATELLO:'
							],type:'none'}
						], margin:[20,0,10,0]},
						{text: '________________________________________@_______________________________.___________', margin:[20,20,10,0]},
						{text:'e conferma di aver ricevuto la password per decriptare il file', margin: [20,0,10,0]},
						{text: 'N.B.: Il/La paziente deve presentarsi entro le ore ___________ del giorno successivo al montaggio per effettuare la rimozione dell\'apparecchio a cura esclusiva del personale', bold: true, margin: [10,10,10,0]},
						{text: 'Dichiara di aver letto e compreso quanto esposto', margin:[10,5,10,0]},
						{text: 'Bari,  '+this.formatDate(today, 'data')+'                    Firma __________________________________________', margin: [10,20,10,10]},
						{text: '----------------------------------------------------------------', alignment: 'center',margin:[10,15,10,15]},
						{
							image: intbase64,
							width: 550,
							alignment: 'center'
							},
						{text: 'Il/La paziente deve presentarsi entro le ore ___________ del giorno successivo al montaggio per effettuare la rimozione dell\'apparecchio a cura esclusiva del personale', bold: true, margin: [10,30,10,20]},
						{text:'Il referto sarà disponibile a partire dal giorno '+this.formatDate(today10, 'data')+'.', bold: true, margin:[10,0,10,0]},
						{text: 'Il ritiro del referto di persona può essere effettuato dal Lunedì al Venerdì dalle ore 10.30 alle ore 12.00.', bold: true, margin: [10,10,10,0]},
						{text: 'la password per decriptare il referto PDF inviato via mail, di sotto indicata, deve essere inserita esattamente come scritta rispettando i caratteri minuscoli e maiuscoli.', margin: [10,10,10,10]},
						{text: 'PASSWORD: '+paziente.password, bold: true, margin: [10,10,10,10],fontSize: 16},
					);
					docDefinition.content=def;
					break;
				case 'InformativaSforzo':
					def=[];
					def.push(
						{text:'Paziente: '+paziente.cognome+' '+paziente.nome+' nato/a il '+this.formatDate(paziente.data_nascita, 'data'), bold: true, margin:[10,10,10,30], alignment: 'center'},
						{text: 'Il test da sforzo, che lei sta per eseguire è un test provocativo, che ha lo scopo di indagare sulle sue condizioni di salute.', margin: [10,0,10,0]},
						{text: 'In particolare l\'aumento del lavoro cardiaco, della frequenza cardiaca, della pressione arteriosa possono provocare ischemia al cuore e permettere di diagnosticare una malattia cardiaca e di curarla. Inoltre lo scopo dell\'esame è provocare un aumento della pressione e di indurre aumento del battito: tutto ciò permette poi di dare delle cure più adeguate al paziente o di fornire delle assicurazioni sulla possibilità di svolgere attività fisica senza rischi per la salute. Durante l\'esame normalmente si manifesta affaticamento e aumento della pressione e del battito.', margin: [10,0,10,0]},
						{text: 'Eventuali sintomi (per esempio affanno intenso, dolore al torace, mal di testa, vertigini), che insorgono durante l\'esame devono essere comunicati tempestivamente al medico, che deciderà se interrompere o continuare l\'esame, anche in ragione dell\'elettrocardiogramma.', margin: [10,0,10,0]},
						{text: 'Infatti, eventuali sintomi o alterazioni dell\'ECG potrebbero precedere eventi avversi anche gravi e molto raramente, fatali.', margin: [10,0,10,0]},
						{text: 'Il personale che esegue l\'esame è preparato per affrontare eventuali emergenze con farmaci e presidi medici.', margin: [10,0,10,0]},
						{text: 'I rischi comuni all\'esecuzione dell\'esame sono tuttavia nettamente inferiori ai benefici che si ottengono in termini di diagnosi e cura di eventuali malattie.', margin: [10,0,10,0]},
						{text: 'Motivo per cui questo esame è inserito dalle organizzazioni scientifiche internazionali in molti percorsi medico-diagnostici.', margin: [10,0,10,0]},
						{text: 'Sono stato informato di eventuali rischi, molto raramente anche fatali, ed accetto di sottopormi a tale esame.', margin: [10,10,10,10]},
						{text: 'Bari '+this.formatDate(today,'data'), margin: [10,20,10,10]},
						{text: 'Stampato alle ore '+this.formatDate(today,'ora'), alignment: 'right', margin: [10,10,10,10]},
						{text: 'Per accettazione alle ore ___:____', alignment: 'right', margin: [10,30,10,30]},
						{text: 'Firma ____________________________________', alignment: 'right', margin: [10,30,10,30]},
					);
						docDefinition.content=def;
						break;
				case 'visitaC':
					def=[];
					def.push(
						int_paziente,
					{columns: [
						{ width: '*', text: '' },
						{
						table:{
							body:[
								
									[{text: 'Altezza: '},{text: anamnesi.altezza+'cm', bold: true},{text: 'Peso: '},{text: anamnesi.peso+'kg', bold: true},{text: 'p.A.: '},{text: anamnesi.pa+'mmHg', bold: true}]
								
							]
						},
						layout: 'noBorders',
						width: 'auto',
						fontSize: 9
					},
					{ width: '*', text: '' }
					]},
					{text: 'Fattori di rischio cardiovascolari', style: 'header'},
					{
						table:{
						body:[
							
								[{text: 'Fumatore: '},{text: anamnesi.fumatore, bold: true},{text: 'Ipertensione arteriosa: '},{text: anamnesi.ipertensione_arteriosa, bold: true},{text: 'Diabete: '},{text: anamnesi.diabete, bold: true},{text: 'Dislipidemia: '},{text: anamnesi.dislipidemia, bold: true}]
							
						]
						},
						layout: 'noBorders',
						width: 'auto',
						margin: [10,0,10,0]
					}
					);
					if(anamnesi.precedenti_diagnosi)
					def.push(
						{text: 'Precedenti diagnosi', style: 'header'},
						{text: anamnesi.precedenti_diagnosi, margin: [10,0,10,0]}
					);
					if(anamnesi.farmaci_assunti)
					def.push(
						{text: 'Farmaci assunti', style: 'header'},
						{text: anamnesi.farmaci_assunti, margin: [10,0,10,0]}
					);
					if(obj.motivo)
					def.push(
						{text: 'Sintesi anamnestica', style: 'header'},
						{text: obj.motivo, margin: [10,0,10,0]}
					);
					if(obj.esameo)
					def.push(
						{text: 'Esame obiettivo', style: 'header'},
						{text: obj.esameo, margin: [10,0,10,0]}
					);
					if(obj.ecg)
					def.push(
						{text: 'ECG', style: 'header'},
						{text: obj.ecg, margin: [10,0,10,0]}
					);
					if(obj.diagnosi)
					def.push(
						{text: 'Diagnosi', style: 'header'},
						{text: obj.diagnosi, margin: [10,0,10,0]}
					);
					if(obj.note)
					def.push(
						{text: 'Note', style: 'header'},
						{text: obj.note, margin: [10,0,10,0]}
					);
					if(obj.prescrizioni)
					def.push(
						{text: 'Prescrizioni', style: 'header'},
						{text: obj.prescrizioni, margin: [10,0,10,0]}
					);
					if(obj.data_prossimo)
					def.push(
						{text: 'Prossimo controllo', style: 'header'},
						{text: obj.data_prossimo, margin: [10,0,10,0]}
					);

					def.push(firma);
					docDefinition.content=def;
					break;
				case 'EcoD':
					def=[];
					def.push(
						int_paziente,
					{text: 'Ecocardiogramma Color-Doppler', bold: true, fontSize: 17, alignment:'center',margin:[0,10,0,20] }
					);
					if(obj.mitrale)
					def.push(
						{text: 'Mitrale', style: 'header'},
						{text: obj.mitrale}
					);
					if(obj.aorta)
					def.push(
						{text: 'Aorta', style: 'header'},
						{text: obj.aorta}
					);
					if(obj.atrio_sinistro)
					def.push(
						{text: 'Atrio sinistro', style: 'header'},
						{text: obj.atrio_sinistro}
					);
					if(obj.ventricolo_sinistro)
					def.push(
						{text: 'Ventricolo sinistro', style: 'header'},
						{text: obj.ventricolo_sinistro}
					);
					if(obj.cuore_destro)
					def.push(
						{text: 'Cuore destro', style: 'header'},
						{text: obj.cuore_destro}
					);
					if(obj.doppler)
					def.push(
						{text: 'Doppler', style: 'header'},
						{text: obj.doppler}
					);
					if(obj.misure_volumetriche)
					def.push(
						{text: 'Misure volumetriche', style: 'header'},
						{text: obj.misure_volumetriche}
					);
					if(obj.note)
					def.push(
						{text: 'Note', style: 'header'},
						{text: obj.note}
					);
					if(obj.conclusioni)
					def.push(
						{text: 'Conclusioni', style: 'header'},
						{text: obj.conclusioni}
					);
					def.push(firma);
					docDefinition.content=def;
					break;
				case 'TSA':
					def=[];
					def.push(int_paziente);
					def.push({text: 'ECO-COLOR-DOPPLER DEI TRONCHI SOVRAORTICI', bold: true, fontSize: 17, alignment:'center',margin:[0,10,0,20] });
					def.push({text: '(Sono state esplorate le arterie carotidi comuni, interne ed esterne, e le arterie vertebrali bilateralmente)', alignment: 'center' });
					if(obj) def.push({text:obj});
					def.push(firma);
					docDefinition.content=def;
					break;
				case 'DOPPLER':
					def=[];
					def.push(int_paziente);
					def.push({text: 'ECO-COLOR-DOPPLER ARTERIOSO O VENOSO ARTI', bold: true, fontSize: 17, alignment:'center',margin:[0,10,0,20] });
					if(obj) def.push({text:obj});
					def.push(firma);
					docDefinition.content=def;
					break;
				case 'DOPPLERDOUBLE':
					def=[];
					def.push(int_paziente);
					def.push({text: 'ECO-COLOR-DOPPLER ARTERIOSO E VENOSO ARTI', bold: true, fontSize: 17, alignment:'center',margin:[0,10,0,20] });
					if(obj) def.push({text:obj});
					def.push(firma);
					docDefinition.content=def;
					break;
				case 'refertazione_privata':
					def=[];
					def.push(int_paziente);
					def.push({text: 'REFERTO', bold: true, fontSize: 17, alignment:'center',margin:[0,10,0,20] });
					if(obj) def.push({text:obj});
					def.push(firma);
					docDefinition.content=def;
					break;
				case 'eco_addome':
					def=[];
					def.push(int_paziente_eta);
					def.push({text: 'Ecografia Addome', bold: true, fontSize: 17, alignment:'center',margin:[0,10,0,20] });
					if(obj) def.push({text:obj});
					def.push(firma_med_interna);
					docDefinition.content=def;
					break;
				case 'visita_ortopedica':
					def=[];
					def.push(int_paziente);
					def.push({text: 'Visita', bold: true, fontSize: 17, alignment:'center',margin:[0,10,0,20] });
					if(obj) def.push({text:obj});
					def.push(firma);
					docDefinition.content=def;
					break;
				case 'eco_tiroide':
					def=[];
					def.push(int_paziente_eta);
					def.push({text: 'Ecografia Tiroidea', bold: true, fontSize: 17, alignment:'center',margin:[0,10,0,20] });
					if(obj) def.push({text:obj});
					def.push(firma_med_interna);
					docDefinition.content=def;
					break;
				case 'EsitoInail':
					def=[];
					def.push(int_paziente);
					def.push({text: 'Esito Piano Riabilitativo', bold: true, fontSize: 17, alignment:'center',margin:[0,10,0,20] });
					if(obj) def.push({text:obj});
					def.push(firma);
					docDefinition.content=def;
					break;
				case 'BPE':
					def=[];
					def.push(int_paziente);
					var xx="";
					if(obj.stabilometrico && !obj.precedente) xx='ESAME STABILOMETRICO STATICO E DINAMICO, CINEMATICO E DINAMOMETRICO';
					if(obj.posturografico) xx+=', TEST POSTUROGRAFICO';
					if(obj.biometria) xx+=', BIOMETRIA 3D';
					if(obj.precedente) xx='ESAME DI CONTROLLO';
					if(obj.biometria && !obj.stabilometrico) xx='BIOMETRIA 3D';
					def.push({text: xx, bold: true, fontSize: 17, alignment:'center',margin:[0,10,0,20] });
					if(obj.stabilometrico) def.push({text:'Il test stabilometrico '+obj.stabilometrico});
					if(obj.statica) def.push({text:'La baropodometria statica '+obj.statica});
					if(obj.dinamica) def.push({text:'La baropodometria dinamica, associata all\'analisi cinematica, '+obj.dinamica});
					if(obj.posturografico) def.push({text:'Il test posturografico '+obj.posturografico});
					if(obj.biometria) def.push({text:'La Biometria 3D '+obj.biometria});
					if(obj.precedente) def.push({text:'Rispetto al precedente esame del '+obj.precedente});
					if(obj.consigli) def.push({text:'Si consiglia: '+obj.consigli});
					def.push(firma);
					docDefinition.content=def;
					break;
				case 'VisitaPneumo':
					def=[];
					var firma2=firma;
					def.push(int_paziente);
					if(obj.visitaP){
						if(obj.visitaP.stampa && obj.visitaP.precedente) def.push({text:'Referti precedenti:', margin:[10,0]},{text: obj.visitaP.precedente, fontSize: 8, italics: true, margin:[10,5]});
						if(obj.visitaP.anamnesi && obj.visitaP.anamnesi!="") def.push({text: 'Anamnesi: '+obj.visitaP.anamnesi, margin:[10,5]});
						if(obj.visitaP.spirometria && obj.visitaP.spirometria!="")def.push({text: obj.visitaP.spirometria, margin:[10,5]});
						if(obj.visitaP.eo && obj.visitaP.eo!="") def.push({text: 'E.O. Toracico: '+obj.visitaP.eo, margin:[10,5]});
						if(obj.visitaP.diagnosi && obj.visitaP.diagnosi!="") def.push({text: 'Diagnosi: '+obj.visitaP.diagnosi, margin:[10,5]});
						if(obj.visitaP.prescrizioni_farmaceutiche && obj.visitaP.prescrizioni_farmaceutiche!="") def.push({text: 'Prescrizioni farmaceutiche: '+obj.visitaP.prescrizioni_farmaceutiche, margin:[10,5]});
						if(obj.visitaP.prescrizioni_altro && obj.visitaP.prescrizioni_altro!="") def.push({text: 'Altre prescrizioni: '+obj.visitaP.prescrizioni_altro, margin:[10,5]});
						if(obj.visitaP.controllo && obj.visitaP.controllo!="") def.push({text: 'Controllo: '+obj.visitaP.controllo, margin:[10,5]});
						def.push(firma);
						if(obj.visitaP.nota99 && obj.visitaP.nota99.diagnosi && obj.visitaP.nota99.diagnosi!=""){
							def.push({text:'SCHEDA DI VALUTAZIONE E PRESCRIZIONE SPECIALISTICA PER LA BPCO', bold: true, fontSize: 15, alignment:'center',pageBreak: 'before',margin:[0,10,0,5]});
							def.push({columns: [
								{ width: '*', text: '' },
								{
								table:{
									body:[
										
											[{text: 'Paziente: '},{text: paziente.cognome+' '+paziente.nome, bold: true},{text: ' nato/a il '},{text: this.formatDate(paziente.data_nascita, 'data'), bold: true}]
										
									]
								},
								layout: 'noBorders',
								width: 'auto',
								fontSize: 13
							},
							{ width: '*', text: '' }
							],
							margin: [10,0,10,10]
							});
							def.push({text:'Verificata la presenza delle seguenti condizioni:', bold: true, fontSize: 14,margin:[0,5,0,5]});
							def.push({text:'[X] - diagnosi clinica e spirometrica di broncopneumopatia cronica ostruttiva (BPCO)' });
							def.push({text:'[X] - FEV1 / FVC (da esame spirometrico) < 0,70 (70%):       '+obj.visitaP.nota99.tiffenau+'(misurato dopo broncodilatazione)'});
							def.push({text: '[X] - '+obj.visitaP.nota99.fev1+':       '+obj.visitaP.nota99.fev1misurato+' (misurato con lo spirometro)'});
							def.push({text:'Terapia inalatoria in atto', bold: true, fontSize: 14,margin:[0,5,0,5]});
							def.push({text:'[X] - '+obj.visitaP.nota99.terapia_in_atto});
							def.push({text: 'Dettagli: '+obj.visitaP.nota99.descrizione_terapia_in_atto});
							def.push({text:'Sezione 1: valutazione clinica e proposta di strategia terapeutica', bold: true, fontSize: 15,margin:[0,15,0,10]});
							def.push({text:'Valutazione clinica della terapia in corso', bold: true, fontSize: 14,margin:[0,5,0,5]});
							def.push({text:'[X] - '+obj.visitaP.nota99.valutazione_clinica});
							def.push({text:'Proposta di strategia terapeutica', bold: true, fontSize: 14,margin:[0,5,0,5]});
							def.push({text:'[X] - '+obj.visitaP.nota99.proposta_terapia});
							def.push({text: 'Dettagli: '+obj.visitaP.nota99.descrizione_proposta_terapia});
							def.push([{text: 'Bari, '+this.formatDate(today, 'data'), margin: [10,30,10,5]},
							{
								columns:[
									{text:'',width:'*'},
									{
										table:{
											body:[
												[{text:nominativo}],
												[{text: 'Art.3 Decreto Legge 39 del 12/02/1993'}]
											]
										},
										layout: 'noBorders',
										alignment: 'center',
										width:'auto'
									}
								],
								margin:[10,5,10,10]
							}]);
							if(obj.visitaP.nota99.proposta_terapia=='LABA+ICS+LAMA (unico inalatore)'){
								def.push({text:'Sezione 2: Piano Terapeutico AIFA (unico per le triplici associazioni fisse)', bold: true, fontSize: 16,margin:[0,15,0,10], pageBreak: 'before'});
								def.push({columns: [
									{ width: '*', text: '' },
									{
									table:{
										body:[
											
												[{text: 'Paziente: '},{text: paziente.cognome+' '+paziente.nome, bold: true},{text: ' nato/a il '},{text: this.formatDate(paziente.data_nascita, 'data'), bold: true}]
											
										]
									},
									layout: 'noBorders',
									width: 'auto',
									fontSize: 13
								},
								{ width: '*', text: '' }
								],
								margin: [10,0,10,10]
								});
								def.push({text:'Piano Terapeutico AIFA per la prescrizione della TRIPLICE terapia LABA/LAMA/ICS (in singoloerogatore) nel trattamento della broncopneumopatia cronica ostruttiva (BPCO)', bold: true, fontSize: 12,margin:[0,5,0,10]});
								def.push({text:'La rimborsabilità SSN è limitata alla terapia di mantenimento dei pazienti adulti con diagnosi di BPCO di grado da moderato a severo, che non siano sufficientemente controllati dall’associazione di un corticosteroide inalatorio e di un beta2-agonista a lunga durata d’azione, laddove siano soddisfatte ENTRAMBE le condizioni di seguito riportate:',margin:[0,5,0,5]});
								def.push({text:'[X] - Paziente con diagnosi conclamata di BPCO da moderata a grave e con almeno 2 riacutizzazioni negli ultimi 12 mesi o almeno una riacutizzazione che ha richiesto il ricovero ospedaliero;'});
								def.push({text:'[X] - Paziente affetto da BPCO con dispnea classificabile come di seguito:'});
								if(obj.visitaP.nota99.mmrc && obj.visitaP.nota99.cat_totale==0){
									def.push({text:'Dispnea di grado 2 o superiore misurata tramite il questionario mMRC per la dispnea:', margin:[0,10,0,10]});
									def.push({text:'Questionario mMRC',bold:true, margin:[0,5,0,5]});
									let temp="";
									if(obj.visitaP.nota99.mmrc==0) temp='[X] - '; else temp='[ ] - ';
									temp+='Grado 0 - Ho dispnea solo per sforzi intensi (es. salire le scale, pedalare).';
									def.push({text: temp});
									if(obj.visitaP.nota99.mmrc==1) temp='[X] - '; else temp='[ ] - ';
									temp+='Grado 1 - Mi manca il fiato se cammino veloce (o corro) in piano o in lieve salita';
									def.push({text: temp});
									if(obj.visitaP.nota99.mmrc==2) temp='[X] - '; else temp='[ ] - ';
									temp+='Grado 2 - Su percorsi piani cammino più lentamente dei miei coetanei, oppure ho necessità di fermarmi per respirare quando cammino a passo normale';
									def.push({text: temp});
									if(obj.visitaP.nota99.mmrc==3) temp='[X] - '; else temp='[ ] - ';
									temp+='Grado 3 - Ho necessità di fermarmi per respirare dopo aver camminato in piano per circa 100 metri o per pochi minuti';
									def.push({text: temp});
									if(obj.visitaP.nota99.mmrc==4) temp='[X] - '; else temp='[ ] - ';
									temp+='Grado 4 - Mi manca il fiato a riposo, per uscire di casa o per vestirmi/spogliarmi';
									def.push({text: temp});
									
								}
								else
								{
									def.push({text:'Punteggio al test di valutazione della sintomatologia CAT pari almeno a 10', margin:[0,10,0,10]});
									def.push({text:'Questionario CAT (COPD Assessment Test - CATTM, punteggio da 0 a 5 per ogni domanda )',bold:true, margin:[0,5,0,5]});
									def.push({
										table:{
											headerRows: 1,
        									widths: [ '*', 'auto'],
											body:[
												[{text:'min / max',bold: true, alignment: 'center'},{text:'Punteggio',bold: true, alignment: 'center'}],
												[{fontSize: 8,text: 'Non tossisco mai / Tossisco sempre'},obj.visitaP.nota99.cat1],
												[{fontSize: 8,text:'Il mio petto è completamente libero da catarro (muco) / Il mio petto è tutto pieno di catarro (muco)'}, obj.visitaP.nota99.cat2],
												[{fontSize: 8,text:'Non avverto alcuna sensazione di costrizione al petto / Avverto una forte sensazione di costrizione al petto'},obj.visitaP.nota99.cat3],
												[{fontSize: 8,text:'Quando cammino in salita o salgo una rampa di scale non avverto mancanza di fiato / Quando cammino in salita o salgo una rampa di scale avverto una forte mancanza di fiato'},obj.visitaP.nota99.cat4],
												[{fontSize: 8,text:'Non avverto limitazioni nello svolgere qualsiasi attività in casa / Avverto gravi limitazioni nello svolgere qualsiasi attività in casa'},obj.visitaP.nota99.cat5],
												[{fontSize: 8,text:'Mi sento tranquillo ad uscire di casa nonostante la mia malattia polmonare / Non mi sento affatto tranquillo ad uscire di casa a causa della mia malattia polmonare'},obj.visitaP.nota99.cat6],
												[{fontSize: 8,text:'Dormo profondamente / Non riesco a dormire profondamente a causa della mia malattia polmonare'},obj.visitaP.nota99.cat7],
												[{fontSize: 8,text:'Ho molta energia / Non ho nessuna energia'},obj.visitaP.nota99.cat8],
												[{text:'TOTALE', alignment: 'right', bold:true},{text: obj.visitaP.nota99.cat_totale, bold: true}]
											]
										}
									})
								}
								def.push({text:'TERAPIA:',bold:true, margin:[0,5,0,5]});
								if(obj.visitaP.nota99.prima_prescrizione) def.push({text: '[X] - PRIMA PRESCRIZIONE', bold: true});
								else def.push({text: '[X] - PROSECUZIONE TERAPIA', bold: true});
								def.push({text:'Posologia: '});
								def.push({text: obj.visitaP.nota99.posologia+': '+obj.visitaP.nota99.descrizione_posologia});
								if(obj.visitaP.nota99.posologia=='Beclometasone dipropionato/formoterolo fumarato diidrato/glicopirronio bromuro (soluzione per inalazione)') def.push({text: '2 erogazioni (ciascuna da 87 mcg/5 mg/9 mcg) due volte al giorno.'});
								else if(obj.visitaP.nota99.posologia=='Beclometasone dipropionato/formoterolo fumarato diidrato/glicopirronio bromuro (polvere per inalazione)') def.push({text: '2 erogazioni (ciascuna da 88 mcg/5 mg/9 mcg) due volte al giorno.'});
								else if(obj.visitaP.nota99.posologia=='Budesonide/glicopirronio bromuro/ formoterolo fumarato diidrato') def.push({text: '2 erogazioni (ciascuna da 160 mcg/7,2 mcg/5 mcg) due volte al giorno.'});
								else def.push({text: '1 inalazione (da 92 mcg/55 mg/22 mcg) ogni giorno alla stessa ora.'});
								def.push({text:'NB per tutti i farmaci le dosi indicate sono anche le dosi massime raccomandate', bold: true, margin: [0,10,0,10]});
								def.push({text:'Validità del Piano Terapeutico: '+obj.visitaP.nota99.validita+' mesi', bold: true});
								def.push([{text: 'Bari, '+this.formatDate(today, 'data'), margin: [10,30,10,5]},
								{
									columns:[
										{text:'',width:'*'},
										{
											table:{
												body:[
													[{text:nominativo}],
													[{text: 'Art.3 Decreto Legge 39 del 12/02/1993'}]
												]
											},
											layout: 'noBorders',
											alignment: 'center',
											width:'auto'
										}
									],
									margin:[10,5,10,10]
								}]);

							}
						}
					}
					else 
					{
						def.push({text: obj.visita});
						def.push(firma);
					}
					docDefinition.content=def;
					break;
				case 'PRPN':
					var nota1,nota2,nota3;
					if(obj.nota1) nota1=obj.nota1; else nota1=' ';
					if(obj.nota2) nota2=obj.nota2; else nota2=' ';
					if(obj.nota3) nota3=obj.nota3; else nota3=' ';
					def=[];
					def.push(int_paziente);
					def.push({text:'Si propone:', bold: true, margin: [10,0]});
					def.push({text:'(N.B.: prescrizioni TERAPEUTICHE afferenti alla BRANCA di PNEUMOLOGIA, e, pertanto a carico del medico di base, in osservanza della nota della Reg. PUGLIA N. 6030, Prot. A00151 del 7 giugno 2013)', bold: true, fontSize: 10, margin:[10,10,10,10]});
					if(obj.si1)
						def.push(
							{text:'Ricetta n°1',margin: [10,0,10,0], bold: true},
							{
								
								alignment:'center',
								table:{
									widths: [ '*', 40, 40],
									body:[
										[{text: 'PRESCRIZIONE', bold: true},{text: 'Q.Tà', bold: true},{text: 'Note', bold: true}],
										[{text: obj.prescrizione1, fontSize: 10, alignment: 'left'},{text: obj.quantita1, fontSize: 10},{text: nota1, fontSize: 10}]
									]
								},
								margin: [10,10,10,10]
							}
							
							);
					if(obj.si2)
						def.push(
							{text:'Ricetta n°2',margin: [10,0,10,0], bold: true},
							{
								
								alignment:'center',
								table:{
									widths: [ '*', 40, 40],
									body:[
										[{text: 'PRESCRIZIONE', bold: true},{text: 'Q.Tà', bold: true},{text: 'Note', bold: true}],
										[{text: obj.prescrizione2, fontSize: 10, alignment: 'left'},{text: obj.quantita2, fontSize: 10},{text: nota2, fontSize: 10}]
									]
								},
								margin: [10,10,10,10]
							}
							
							);
					if(obj.si3)
						def.push(
							{text:'Ricetta n°3',margin: [10,0,10,0], bold: true},
							{
								
								alignment:'center',
								table:{
									widths: [ '*', 40, 40],
									body:[
										[{text: 'PRESCRIZIONE', bold: true},{text: 'Q.Tà', bold: true},{text: 'Note', bold: true}],
										[{text: obj.prescrizione3, fontSize: 10, alignment: 'left'},{text: obj.quantita3, fontSize: 10},{text: nota3, fontSize: 10}]
									]
								},
								margin: [10,10,10,10]
							}
							
							);
						def.push({text: '(proposta di prescrizione a norma del D.M. 22/07/96, del R.R. 17/2003, del D.M. 9/12/2015, della Circolare esplicativa della Regione Puglia N. 62466, Prot. A00151 del 29 marzo 2016 e s. i. e m. e della DGR Puglia n. 1863 del 23/12/2024 in recepimento del DPCM del 12/01/2017)', fontSize: 8, margin:[10,0]});
						if(obj.prescrizione_farmaceutica)
						def.push({
							table:{
								body:[
									[{text: 'PRESCRIZIONE FARMACEUTICA:', bold: true},obj.prescrizione_farmaceutica]
								],
								widths:['auto','*']
							},
							layout: 'noBorders',
							margin:[10,20,10,10]
						});
						if(obj.altre_prescrizioni)
						def.push({
							table:{
								body:[
									[{text: 'ALTRE PRESCRIZIONI:', bold: true},obj.altre_prescrizioni]
								],
								widths:['auto','*']
							},
							layout: 'noBorders',
							margin:[10,10]
						});
						if(obj.diagnosi)
						def.push({
							table:{
								body:[
									[{text: 'DIAGNOSI:', bold: true},obj.diagnosi]
								],
								widths:['auto','*']
							},
							layout: 'noBorders',
							margin:[10,10]
						});
						def.push({
							table:{
								body:[
									[{text: 'NOTE:', bold: true},'La presente prescrizione è valida per n° '+obj.cicli+' cicli di terapia.']
								],
								widths:['auto','*']
							},
							layout: 'noBorders',
							margin:[10,10]
						});
						//def.push({text: '* Trattandosi di organo bilaterale vanno considerate 2 sedute/dì, come da nota del Ministero della Sanità n. 900/U.C./981 del 07/10/93 e dell\'Assessorato Sanità della Regione Puglia n. 24/27636 del 2/11/93.', fontSize: 8, margin:[10,0]});
						def.push(firma);
						docDefinition.content=def;
					break;
				case 'TestCammino':
				case 'TestCamminoCDCP':
					def=[];
					def.push(int_paziente_eta);
					def.push(
						{text: 'TEST DEL CAMMINO', bold: true, fontSize: 12, alignment: 'center',margin:[0,10,0,0]},
						{text: '(Il test ha una durata teorica di 6 min)', alignment: 'center'}
					);
					def.push(
						{
							margin:[10,20,10,5],
							alignment: 'center',
							table:{
								widths:[260,40],
								heights:[20,20,20,20,20,20,5,20,20],
								body:[
									[{text: 'ALTEZZA (cm):', bold: true, alignment: 'right'},obj.altezza],
									[{text: 'PESO (kg):', bold: true, alignment: 'right'},obj.peso],
									[{text: 'SESSO:', bold: true, alignment: 'right'},obj.sesso],
									[{text: 'BMI (Indice di Massa Coroporea kg/m2):', bold: true, alignment: 'right'},obj.BMI.toFixed(2)],
									[{text: 'FI O2(%):', bold: true, alignment: 'right'},obj.fi*100],
									[{text: 'FREQUENZA CARDIACA MAX (bpm):', bold: true, alignment: 'right'},obj.bpm_max],
									['',''],
									[{text: 'PERCORRENZA TEORICA (m):', bold: true, alignment: 'right'},obj.percorrenza_teorica.toFixed(2)],
									[{text: 'PERCORRENZA MISURATA (m):', bold: true, alignment: 'right'},obj.percorrenza_misurata]
								]
							}
						}
					);
					var recupero="n.d.";
					if(obj.recupero) recupero=obj.recupero;
					def.push(
						{
							margin:[10,5],
							alignment: 'center',
							table:{
								widths:[260,40,40,40],
								heights:[20,20,20,20,5,20],
								body:[
									['',{text: 'PRE', bold: true},{text: 'POST', bold: true},{text: '% max', bold: true}],
									[{text: 'SAT HB-O2 %:', bold: true, alignment:'right'},obj.sat_pre,obj.sat_post,''],
									[{text: 'FREQUENZA CARDIACA (bpm):', bold: true, alignment:'right'},obj.bpm_pre,obj.bpm_post, obj.bpm_perc.toFixed(2)],
									[{text: 'BORG (range 1-10):', bold: true, alignment:'right'},obj.borg_pre,obj.borg_post,''],
									['','','',''],
									[{text: 'TEMPO DI RECUPERO:', bold: true, alignment:'right'},{text: recupero, colSpan: 3},'','']
								]
							}
						}
					);
					var note="n.d.";
					if(obj.note) note=obj.note;
					def.push(
						{
							margin:[10,5],
							alignment: 'center',
							table:{
								widths:['*'],
								heights:[20],
								body:[
									[{text: 'NOTE e CONCLUSIONI', bold: true}],
									[note]
								]
							}
						}
					);
					def.push(firma);
					docDefinition.content=def;
						break;
				case 'PrickTest':
                              if(!obj.hasOwnProperty("pt_elements")){
								var x=obj;
								obj= new pricktest;
                                if(x.positivo) obj.positivo=x.positivo; else obj.posiivo=0;
                                if(x.negativo) obj.negativo=x.negativo; else obj.negativo=0;
								obj.set=1;
                                if(obj.conclusioni) obj.conclusioni=x.conclusioni; else obj.conclusioni="";
                                var keys=Object.keys(x);
                                keys.forEach(key=>{
                                  if(key!='positivo' && key!='negativo'&& key!='conclusioni') obj.pt_elements[obj.pt_elements.findIndex(el=>el.name==key)].value=x[key];
                                });
                                }
							def=[];
							def.push(int_paziente);
							def.push({text: 'PROVE ALLERGOLOGICHE CUTANEE (Prick test)', bold: true, fontSize: 12, alignment: 'center', margin:[10,0,10,20]});
							var colonna_inalanti= new Array<any>();
							colonna_inalanti.push([{text: 'INALANTI', alignment: 'center', bold: true, colSpan: 5},'','','','']);
							var colonna_alimenti= new Array<any>();
							colonna_alimenti.push([{text: 'ALIMENTI', alignment: 'center', bold: true, colSpan: 5},'','','','']);
							obj.pt_elements.forEach(element => {
								if(element.set==obj.set && element.group==1) colonna_inalanti.push(this.return_value(element));
								if(element.set==obj.set && element.group==2) colonna_alimenti.push(this.return_value(element));
							});
							def.push(
								{
									columns: [{
										//INALANTI
										
										margin:[10,0,0,0],
										table:{
											widths:['*','auto','auto','auto','auto'],
											body: colonna_inalanti
										}
									},
									{
										//ALIMENTI
										
										margin:[0,0,10,0],
										table:{
											widths:['*','auto','auto','auto','auto'],
											body: colonna_alimenti
										}
									}]
								}
							);
							var positivo;
							var negativo;
							switch(obj.positivo){
								case 0: positivo=[{text: 'Controllo positivo'},'-','-','-','-',''];break;
								case 1: positivo=[{text: 'Controllo positivo'},{text: '+', color: '#00ff00'},'-','-','-'];break;
								case 2: positivo=[{text: 'Controllo positivo'},{text: '+', color: '#00ff00'},{text: '+', color: '#00ff00'},'-','-',''];break;
								case 3: positivo=[{text: 'Controllo positivo'},{text: '+', color: '#00ff00'},{text: '+', color: '#00ff00'},{text: '+', color: '#00ff00'},'-',''];break;
								case 4: positivo=[{text: 'Controllo positivo'},{text: '+', color: '#00ff00'},{text: '+', color: '#00ff00'},{text: '+', color: '#00ff00'},{text: '+', color: '#00ff00'},''];break;
							}
							switch(obj.negativo){
								case 0: negativo=[{text: 'Controllo positivo'},'-','-','-','-',''];break;
								case 1: negativo=[{text: 'Controllo positivo'},{text: '+', color: '#00ff00'},'-','-','-',''];break;
								case 2: negativo=[{text: 'Controllo positivo'},{text: '+', color: '#00ff00'},{text: '+', color: '#00ff00'},'-','-',''];break;
								case 3: negativo=[{text: 'Controllo positivo'},{text: '+', color: '#00ff00'},{text: '+', color: '#00ff00'},{text: '+', color: '#00ff00'},'-',''];break;
								case 4: negativo=[{text: 'Controllo positivo'},{text: '+', color: '#00ff00'},{text: '+', color: '#00ff00'},{text: '+', color: '#00ff00'},{text: '+', color: '#00ff00'},''];break;
							}
							def.push({
										margin:[10,0,10,0],
										table:{
											widths:['auto','auto','auto','auto','auto','*'],
											body: [
												negativo,
												positivo,
												[{text: 'CONCLUSIONI DIAGNOSTICHE', bold: true},{text: obj.conclusioni, colSpan: 5},'','','','']
											]
										}
							});
							def.push(firma);
							docDefinition.content=def;
									
							break;
						case 'PRI':
								def=[];
								def.push(int_paziente);
								def.push({text: 'Visita Fisiatrica', bold: true, fontSize: 13, alignment: 'center', margin:[10,0,10,20]});
								def.push({text: 'Diagnosi', bold: true, margin:[10,10]},{text: obj.diagnosi, margin:10});
								if(obj.terapie_asl.length || obj.terapie.length) def.push({text: 'Piano Riabilitativo', bold: true, alignment: 'center', margin:[10,0,10,10]});
								var tabella_asl;
								var tabella;
								console.log(obj)
								if(obj.terapie_asl.length) 
								{
									tabella_asl=[
										[{text: 'L. E. A.', bold: true, alignment: 'center', colSpan: 3, fillColor: '#0099cd', color: '#ffffff'},'',''],
										[{text: 'Terapia', bold: true, alignment: 'center', fillColor: '#b0cfe3'},{text: 'Segmento', bold: true, alignment: 'center', fillColor: '#b0cfe3'},{text: 'N° sedute', bold: true, alignment: 'center', fillColor: '#b0cfe3'}]
									];
									obj.terapie_asl.forEach(element => {
										tabella_asl.push([element.terapia,element.segmento, {text: element.quantita_prescritta, alignment: 'center'}])
									});
									def.push(
										{
											margin: [10,10],
											table:{
												widths:['*','auto','auto'],
												body: tabella_asl
											}
										}
									)
								}
								if(obj.terapie.length) 
								{
									tabella=[
										[{text: 'L. I. A.', bold: true, alignment: 'center', colSpan: 3, fillColor: '#0099cd', color: '#ffffff'},'',''],
										[{text: 'Terapia', bold: true, alignment: 'center', fillColor: '#b0cfe3'},{text: 'Segmento', bold: true, alignment: 'center', fillColor: '#b0cfe3'},{text: 'N° sedute', bold: true, alignment: 'center', fillColor: '#b0cfe3'}]
									];
									obj.terapie.forEach(element => {
										tabella.push([element.terapia,element.segmento, {text: element.quantita_prescritta, alignment: 'center'}])
									});
									def.push(
										{
											margin: [10,10],
											table:{
												widths:['*','auto','auto'],
												body: tabella
											}
										}
									)
								}
								if(obj.altro!="" && obj.altro) def.push({text: 'Altre prescrizioni', bold: true, margin:[10,10]},{text: obj.altro, margin:10});
								def.push(firma);
								docDefinition.content=def;
								break;
					case 'TestSforzo':
							let temp=100*(obj.frequenza/(220-obj.eta));
							var max=temp.toFixed(2);
							def=[];
							def.push(int_paziente_eta);
							def.push(
								{text: 'ECG DA SFORZO AL CICLOERGOMETRO', bold: true, fontSize: 14, alignment: 'center'},
								{text: '(Test di tipo scalare a partenza da 25 Watt, con incrementi di 25 Watt ogni 2 minuti)', alignment: 'center'}
							);
							if(obj.terapia) def.push({text:'Terapia: '+obj.terapia, margin:[10,10]});
							def.push(
								{text: 'RISULTATI', bold: true, fontSize: 12, alignment: 'center',margin:[10,20]},
								{
									margin:[10,10],
									table:{
										widths:['*','auto','auto','auto'],
										body:[
											['',{text: 'FREQUENZA CARDIACA', bold: true},{text: 'PRESSIONE ARTERIOSA', bold: true},{text: 'DOPPIO PRODOTTO', bold: true}],
											[{text:'BASE',alignment: 'right'},obj.frequenza_base+' /m\'',obj.pressione_max_base+'/'+obj.pressione_min_base+' mmHg',obj.doppio_prodotto_base],
											[{text:'LAVORO MASSIMO',alignment: 'right'},obj.frequenza+' /m\'',obj.pressione_max+'/'+obj.pressione_min+' mmHg',obj.doppio_prodotto]
										]
									}
								},
								{text: 'Raggiunto il '+max+'% della frequenza cardiaca massima teorica', margin:[10,10]},
								{text: 'Il Test è stato interrotto al carico massimo di  '+obj.interrotto+' per '+obj.motivo, margin:[10,10]},
								{text: 'Durante il test sono comparsi', bold: true, fontSize: 13,margin:[10,10]},
								{text: 'Sintomi soggettivi: '+obj.sintomi_soggettivi,margin:[10,0]},
								{text: 'Modificazioni del tratto ST-T: '+obj.modificazioni_st,margin:[10,0]},
								{text: 'Aritmie: '+obj.aritmie,margin:[10,0]},
								{text: 'Turbe della Conduzione: '+obj.turbe_conduzione,margin:[10,0]},
								{text: 'Conclusioni', bold: true, fontSize: 13,margin:[10,10]},
								{text: obj.conclusioni,margin:[10,0]},
								firma
							)

							docDefinition.content=def;
							break;
					
				
			}
			if(stampa==0) pdfMake.createPdf(docDefinition).open();
			if(stampa==1) pdfMake.createPdf(docDefinition).print();
			if(stampa==2) pdfMake.createPdf(docDefinition).download(titolo+'.pdf');
		});

			
		
			
		}

		return_value(obj){
			switch(obj.value){
				case 0: return [{text: obj.display_name},'-','-','-','-'];break;
				case 1: return [{text: obj.display_name},{text: '+', color: '#00ff00'},'-','-','-'];break;
				case 2: return [{text: obj.display_name},{text: '+', color: '#00ff00'},{text: '+', color: '#00ff00'},'-','-'];break;
				case 3: return [{text: obj.display_name},{text: '+', color: '#00ff00'},{text: '+', color: '#00ff00'},{text: '+', color: '#00ff00'},'-'];break;
				case 4: return [{text: obj.display_name},{text: '+', color: '#00ff00'},{text: '+', color: '#00ff00'},{text: '+', color: '#00ff00'},{text: '+', color: '#00ff00'}];break;
			}
		}

		formatDate(date,type) {
		  
			date= new Date(date);
			var day = this.addZero(date.getDate());
			var monthIndex = this.addZero(date.getMonth()+1);
			var year = this.addZero(date.getFullYear());
			var hours= this.addZero(date.getHours());
			var minutes=this.addZero(date.getMinutes());
		  
			if(type=='data') return day + '/' + monthIndex + '/' + year;
			if(type=='ora') return hours+':'+minutes;
			if(type=='tutto') return day + '/' + monthIndex + '/' + year+' - '+hours+':'+minutes;
		  }

		  addZero(i) {
			if (i < 10) {
			  i = "0" + i;
			}
			return i;
		  }
		
    }