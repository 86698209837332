export class Holter{
    id;
    id_paziente;
    nome;
    cognome;
    data_nascita;
    cognome_nome;
    tipo;
    stato;
    asl;
    data_montaggio;
    serial_number;
    password;
    operatore: any;
}