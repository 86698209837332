import { Component, OnInit,ViewEncapsulation } from '@angular/core';
import { ComponentCanDeactivate } from '../../_guards/deactivate';
import { Pazienti_schede,Scheda_Visita,Terapia_visita,Scheda,Terapia,EcoD,ECG_SFORZO,VisitaC,Anamnesi,BPE, VP, PRPN, pricktest, Test_Cammino } from '../../_models';
import { GlobalServices } from '../../_services';
import { ActivatedRoute, Router, CanDeactivate } from '@angular/router';
import { Timestamp } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { HostListener } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-visite',
  templateUrl: './visite.component.html',
  styleUrls: ['./visite.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class VisiteComponent implements OnInit,ComponentCanDeactivate {

  spiro=['Il paziente non esibisce alcuna spirometria.','La spirometria esibita è nella norma per indice di Tiffenau e/o FEV1.','Il paziente ha eseguito in data odierna spirometria con indice di Tiffeneau inferiore al 70% e FEV1 inferiore al 100%.','Il paziente esibisce una spirometria eseguita meno di 6 mesi dalla data odierna con indice di Tiffeneau inferiore al 70% e FEV1 inferiore al 100%.']
  visitaP= new pneumo;
  paziente= new Pazienti_schede;
  anamnesi= new Anamnesi;
  visitaC= new VisitaC;
  EcoD= new EcoD;
  bpe= new BPE;
  biometria;
  esitoinail;
  modificato;
  tsa: string;
  doppler_arti: string;
  doppler_arti_double: string;
  refertazione_privata:string;
  eco_addome: string;
  eco_tiroide: string;
  ecg_sforzo= new ECG_SFORZO;
  scheda=new Scheda_Visita;
  scheda_union=new Scheda;
  terapia_temp= new Terapia;
  prpn= new PRPN;
  visite: Array<any>;
  vp= new VP;
  pr;
  somm;
  terapies;
  terapie_asl;
  segmenti;
  oggetto_json;
  tipo_oggetto;
  priorita;
  branca;
  creare_scheda=true;
  anamnesi_salvata;
  access_level;  
  union= new Union();
  impostazioni_cdcp= new Object;
  impostazioni_cs= new Object;
  impostazioni_m2= new Object;
  nominativo: string;
  today= new Date();
  eta;
  array_vp=new Array<any>();
  data_prima_vp;
  data_ultima_vp;
  selectBranca=0;
  selectCardiologia=0;
  selectPneumologia=0;
  selectFisiatria=0;
  prick= new pricktest;
  testC= new Test_Cammino;
  cig;
  years;
  lavoro;
  fkt;
  set_pr;
  p;
  visita_ortopedica;
  qd;
  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if(this.modificato) return true;
    else return false;
  }

  constructor(public service: GlobalServices, private route: ActivatedRoute, private router: Router, private notifiche_bottom: MatSnackBar) { }

  ngOnInit() {
    this.set_pr='2';
    this.fkt=0;
    this.modificato=0;
    this.esitoinail="Si attesta che il/la paziente in oggetto, al termine del piano riabilitativo prescritto, ha ottenuto il miglioramento della articolarità dell'organo infortunato, con riduzione del dolore.\n\rEventuali postumi da valutare in altra sede.\n\rSi rilascia per gli usi consentiti dalla Legge.";
    this.EcoD.mitrale="lembi ispessiti non stenotici/lembi valvolari normali, conservata apertura diastolica";
    this.EcoD.aorta="cuspidi normali/ispessite; conservata apertura sistolica; radice aortica e tratto tubulare aorta ascendente di dimensioni normali fin dove esplorabile";
    this.EcoD.atrio_sinistro="di normali dimensioni/lievemente dilatato(DAP mm)";
    this.EcoD.ventricolo_sinistro="di normali dimensioni (DTD mm); ipertrofia uniforme di grado lieve (SIV mm); conservata funzione sistolica globale e regionale (FEVS stimata)";
    this.EcoD.cuore_destro="ventricolo destro di normali dimensioni; atrio destro di normali dimensioni; vena cava inferiore di normale calibro e collassabilità respiratoria";
    this.EcoD.doppler="inversione del rapporto E/A transmitralico; rigurgito mitralico lieve; rigurgito tricuspidale lieve";
    this.tsa="Spessore intima-media:    mm(v.n.<0.9mm).\n\n\rAssenza di lesioni stenosanti a carico degli assi carotidei bilateralmente.\n\n\rFlusso vertebrale bilateralmente nella norma.";
    this.doppler_arti_double="Asse arterioso:\n\nAsse venoso:";
    this.eco_addome="Esame Ecografico eseguito con Ecografo Samsung HS40\n(COD. ICD-9-CM 88.76)\n\nNOTIZIE CLINICHE:\n\nFEGATO:\n\nCOLECISTI:\n\nPANCREAS:\n\nMILZA:\n\nRENI:\n\nVESCICA:\n\nCONCLUSIONI:\n\n";
    this.eco_tiroide="Esame Ecografico eseguito con Ecografo Samsung HS40\n(COD. ICD-9-CM 88.76)\n\nNOTIZIE CLINICHE:\n\nTIROIDE:\n\nCONCLUSIONI:\n\n";
    this.prpn.prescrizione_farmaceutica=" Pr.: BREVA Soluzione OS Aerosol 15 ml \n Pr.: AIRCORT 0,5 mg/ ml 10 fl x aerosol ";
    this.service.get_function(this.service.apiGetImpostazioni+'cs').subscribe(data=>this.impostazioni_cs=JSON.parse(data[0].value));
    this.service.get_function(this.service.apiGetImpostazioni+'m2').subscribe(data=>this.impostazioni_m2=JSON.parse(data[0].value));
    this.service.get_function(this.service.apiGetImpostazioni+'cdcp').subscribe(data=>this.impostazioni_cdcp=JSON.parse(data[0].value));
    this.service.get_function(this.service.apiGetNominativo).subscribe(data=>this.nominativo=data[0].nominativo);
    this.access_level=this.service.get_access_level();
    this.visitaP.stampa=true;
    if(this.access_level==1 || this.access_level==9) 
    {
      this.service.get_function(this.service.apiGetTerapie+'asl/').subscribe(data=>this.terapie_asl=data);
      this.service.get_function(this.service.apiGetTerapie+'noasl/').subscribe(data=>this.terapies=data);
      this.service.get_function(this.service.apiGetSegmenti).subscribe(segmenti=> this.segmenti=segmenti);
    }    
    switch(this.access_level){
      case 6:
      case 7:
      case 8: this.branca='Cardiologia'; break;
      case 9: this.branca='Fisiatria'; break;
      case 10: this.branca='Pneumologia'; break;
    }
    this.route.params.forEach(params => {
      this.paziente.idscheda=params['id'];
      if(params['priorita']) 
      {
        if(params['priorita'].charAt(3)=='1') this.priorita=1;
        else if(params['priorita'].charAt(3)=='2')this.priorita=2;
        else if(params['priorita'].charAt(3)=='3')this.priorita=3;
        else if(params['priorita'].charAt(3)=='4')this.priorita=4;
        else if(params['priorita'].charAt(3)=='5')this.priorita=5;
        else if(params['priorita'].charAt(3)=='6')this.priorita=6;
        else if(params['priorita'].charAt(3)=='8')this.priorita=8;
        else if(params['priorita'].charAt(3)=='7')this.priorita=7;
        else if(params['priorita'].charAt(3)=='9')this.priorita=9;
        else if(params['priorita'].charAt(3)=='a')this.priorita='a';
        else if(params['priorita'].charAt(3)=='b')this.priorita='b';
        else if(params['priorita'].charAt(3)=='c')this.priorita='c';
        else if(params['priorita'].charAt(3)=='d')this.priorita='d';
        else if(params['priorita'].charAt(3)=='e')this.priorita='e';
        else if(params['priorita'].charAt(3)=='f')this.priorita='f';
        else if(params['priorita'].charAt(3)=='g')this.priorita='g';
        else if(params['priorita'].charAt(3)=='z')this.priorita='z';
        else if(params['priorita'].charAt(3)=='y')this.priorita='y';
        else this.priorita=0;
      }
      //console.log(this.priorita);
      if(params['branca']) this.selectBranca=parseInt(params['branca']);
      if( this.paziente.idscheda) {
        this.CaricaPaziente();
        this.CaricaAnamnesi();
        this.CaricaVisita();
      }
    });
  }

  trackByIndex(val){
    return val;
  }

  ChangeP(p,num){
    if(p=="positivo") this.prick.positivo=num;
    else if(p=="negativo") this.prick.negativo=num;
    else 
    for(var i=0; i<this.prick.pt_elements.length;i++){
      if(this.prick.pt_elements[i].name==p && this.prick.pt_elements[i].set==this.set_pr) this.prick.pt_elements[i].value=num;
    }
  }

  SelectIndex(num,index){
    switch(num){
      case 0:
        this.selectBranca=index;
        break;
      case 1:
        this.selectBranca=0;
        this.selectCardiologia=index;
        break;
      case 2:
        this.selectBranca=1;
        this.selectPneumologia=index;
        break;
      case 3:
        this.selectBranca=2;
        this.selectFisiatria=index;
        break;
    }
  }

  AddTratt(obj,temp){

    var x;
    switch(temp){
      case 'FKT':
        if(this.visitaP[obj]) this.visitaP[obj]+='FKT prescritta a parte'+'\n';
          else this.visitaP[obj]='FKT prescritta a parte'+'\n';
          this.prpn.diagnosi=this.visitaP.diagnosi;
          this.prpn.si1=true;
          this.prpn.si2=true;
          this.prpn.si3=false;
          this.Salva('PRPN');
        break;
      case 'PackYears':
        if(this.visitaP[obj]) this.visitaP[obj]+="Pack Years: "+(this.cig/20)*this.years+'\n';
        else this.visitaP[obj]="Pack Years: "+(this.cig/20)*this.years+'\n';
        break;
      case 'Lavoro':
        if(this.visitaP[obj]) this.visitaP[obj]+="Attività lavorativa attuale o pregressa: "+this.lavoro+'\n';
        else this.visitaP[obj]="Attività lavorativa attuale o pregressa: "+this.lavoro+'\n';
        break;
      case '':
        if(this.visitaP[obj]) this.visitaP[obj]+="Pr.: "+this.pr+" S.: "+this.somm+'\n';
        else this.visitaP[obj]="Pr.: "+this.pr+" S.: "+this.somm+'\n';
        this.pr="";
        this.somm="";
        break;
      case 'Spirometria':
        x='8901L(12178) Visita Pneumologica di Controllo, 89372(49321) Spirometria globale, 89381(49371) Resistenze delle vie aeree, 89384(49401) Compliance polmonare statica e dinamica, 89385(49411) Determinazione del pattern respiratorio a riposo, 89387(49431) Determinazione delle massime pressioni inspiratorie ed espiratorie, 89389(49451) Determinazione della Po.1'
        if(this.visitaP[obj]) this.visitaP[obj]+=x+' '+'\n';
          else this.visitaP[obj]=x+' '+'\n';
        break;
      default:
          if(this.visitaP[obj]) this.visitaP[obj]+=temp+' '+'\n';
          else this.visitaP[obj]=temp+' '+'\n';
          break;
    }
  }

  CaricaVisita()
  {
    let tipi_pneumologia=['VisitaPneumo','Visita_di_controllo','Visita_privata','Visita_+_Sp.Globale','Visita_+_Sp.Completa', 'Visita_+_Sp.Semplice', 'Visita_+_Sp.Basale_con_Broncodilatazione','Sp.Globale','Sp.Semplice','Sp.Basale_con_Broncodilatazione','Sp.Completa','Controllo_con_Sp.Semplice','Controllo_con_Sp.Globale','Controllo_con_Sp.Completa','Controllo_con_Sp.Basale_con_Broncodilatazione','Polisonnografia','dlco'];
    this.service.get_function(this.service.apiGetVisite+this.paziente.idscheda).subscribe(
      dati=>
      {
        if(dati) this.visite=dati;
        
        this.visite.forEach(element=>{
          let today= new Date().toJSON().slice(0,10).replace(/-/g,'-');
          if(tipi_pneumologia.indexOf(element.tipo)>=0) this.array_vp.push(element);
          if(element.data==today){
            switch(element.tipo){
              case 'VisitaControllo':
              case 'VisitaPriority':
              case 'PrimaVisitaPriority':
              case 'ControlloConEcg':
              case 'ECG':
              case 'Visita':
                              this.visitaC=JSON.parse(element.valore);
                              break;
              case 'EcocardiogrammaColorDoppler':
                              this.EcoD=JSON.parse(element.valore);
                              break;
              case 'TSA':
                              this.tsa= JSON.parse(element.valore);
                              break;
              case 'DopplerArti':
                              this.doppler_arti=JSON.parse(element.valore);
                              break;
              case 'DopplerArtiDouble':
                              this.doppler_arti_double=JSON.parse(element.valore);
                              break;
              case 'refertazione_privata':
                              this.refertazione_privata=JSON.parse(element.valore);
                              break;
              case 'visita_ortopedica':
                              this.visita_ortopedica=JSON.parse(element.valore);
                              break;
              case 'eco_addome':
                              this.eco_addome=JSON.parse(element.valore);
                              break;
              case 'eco_tiroide':
                              this.eco_tiroide=JSON.parse(element.valore);
                              break;
              case 'PRI':
                              this.scheda=JSON.parse(element.valore);
                              break;
              case 'TestSforzo':
                              this.ecg_sforzo=JSON.parse(element.valore);
                              break;
              case 'BPE':
                              this.bpe=JSON.parse(element.valore);
                              break;
              case 'EsitoInail':
                              this.esitoinail=JSON.parse(element.valore);
                              break;
              case 'PRPN':
                              this.prpn=JSON.parse(element.valore);
                              break;
              case 'PrickTest':
                              var x=JSON.parse(element.valore);
                              if(!x.hasOwnProperty("pt_elements")){
                                this.prick.positivo=x.positivo;
                                this.prick.negativo=x.negativo;
                                this.prick.conclusioni=x.conclusioni;
                                var keys=Object.keys(x);
                                keys.forEach(key=>{
                                  if(key!='positivo' && key!='negativo'&& key!='conclusioni') this.prick.pt_elements[this.prick.pt_elements.findIndex(el=>el.name==key)].value=x[key];
                                });
                                }
                                else this.prick=x;
                              break;
              case 'TestCamminoCDCP':
              case 'TestCammino':
                              this.testC=JSON.parse(element.valore);
                              break;
            }
            
          }
        })

        if(this.array_vp.length){
          this.data_prima_vp=this.array_vp[0].data;
          this.data_ultima_vp=this.array_vp[this.array_vp.length-1].data;
          let today= new Date().toJSON().slice(0,10).replace(/-/g,'-');
          if(this.data_ultima_vp==today){
            this.visitaP=JSON.parse(this.array_vp[this.array_vp.length-1].valore).visitaP;
            this.visitaP.precedente=JSON.parse(this.array_vp[this.array_vp.length-2].valore).visita;
          }
          else
          this.visitaP.precedente=JSON.parse(this.array_vp[this.array_vp.length-1].valore).visita;
        }
        
      });
  }


  CaricaAnamnesi(){
    this.service.get_function(this.service.apiGetAnamnesi+this.paziente.idscheda).subscribe(
      data=>{
        if(data){
          this.anamnesi=JSON.parse(data[0].valore);
          this.anamnesi_salvata="Anamnesi salvata il " + new Date(data[0].data).toLocaleString().substring(0,9);
          this.testC.altezza=this.anamnesi.altezza;
            this.testC.peso=this.anamnesi.peso;
        }
        else
        this.anamnesi_salvata="Nessuna Anamnesi salvata per questo paziente"
      }
    );
  }

  CaricaPaziente(){

    //caricamento quesito diagnostico da cup per la data visita assegnata
    let x= new Date();
    let da='d'+x.toLocaleDateString().replace(/\//g, "");
    this.service.get_function(this.service.apiGetMeta+'diagnosi/'+da+'/'+this.paziente.idscheda).subscribe(
      qud=> {if(qud) this.qd=qud[0].meta_value;
        }
    );
    //fine

    this.service.get_function(this.service.apiGetPazienti+this.paziente.idscheda).subscribe(data=>{
      this.paziente=data[0];
      if(typeof this.paziente.data_nascita === "string") {
        var ageDifMs = Date.now() - new Date(this.paziente.data_nascita).getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        this.ecg_sforzo.eta=Math.abs(ageDate.getUTCFullYear() - 1970);
      }
      else {
        var ageDifMs = Date.now() - this.paziente.data_nascita.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        this.ecg_sforzo.eta=Math.abs(ageDate.getUTCFullYear() - 1970);
      }
    });
  }

  SalvaAnamnesi(){this.service.post_function(JSON.stringify(this.anamnesi), this.service.apiPostAnamnesi+'Cardiologia'+'/'+this.paziente.idscheda).subscribe(
      data=>this.anamnesi_salvata="Salvata alle ore: " + new Date().toLocaleString()
    )
  }

  AggiungiTesto(testo,obj){
    switch(obj){
      case 1:
        if(this.visitaC.motivo)
        this.visitaC.motivo+=testo;
        else this.visitaC.motivo=testo;
        break;
      case 2:
          if(this.visitaC.esameo)
        this.visitaC.esameo+=testo;
        else this.visitaC.esameo=testo;
        break;
      case 3:
          if(this.visitaC.ecg)
        this.visitaC.ecg+=testo;
        else this.visitaC.ecg=testo;
        break;
      case 4:
          if(this.visitaC.diagnosi)
        this.visitaC.diagnosi+=testo;
        else this.visitaC.diagnosi=testo;
        break;
      case 5:
          if(this.visitaC.note)
        this.visitaC.note+=testo;
        else this.visitaC.note=testo;
        break;
      case 6:
          if(this.visitaC.prescrizioni)
        this.visitaC.prescrizioni+=testo;
        else this.visitaC.prescrizioni=testo;
        break;

    }
  }

  Salva(tipo){

    var obj

    switch(tipo){
      case 'visitaC':
                      this.oggetto_json=JSON.stringify(this.visitaC);
                      obj=this.visitaC;
                      if(this.priorita==1) this.tipo_oggetto='VisitaPriority';
                      else if(this.priorita==2) this.tipo_oggetto='VisitaControllo';
                      else if(this.priorita==3) this.tipo_oggetto='PrimaVisitaPriority';
                      else if(this.priorita==7) this.tipo_oggetto='ControlloConEcg';
                      else if(this.priorita==9) this.tipo_oggetto='ECG';
                      else this.tipo_oggetto='Visita';
                      this.branca='Cardiologia';
                      break;
      case 'EcoD':
                      this.oggetto_json=JSON.stringify(this.EcoD);
                      obj=this.EcoD;
                      this.tipo_oggetto='EcocardiogrammaColorDoppler';
                      this.branca='Cardiologia';
                      break;
      case 'TSA':
                      this.oggetto_json=JSON.stringify(this.tsa);
                      obj=this.tsa;
                      this.tipo_oggetto='TSA';
                      this.branca='Cardiologia';
                      break;
      case 'DOPPLER':
                      this.oggetto_json=JSON.stringify(this.doppler_arti);
                      obj=this.doppler_arti;
                      this.tipo_oggetto='DopplerArti';
                      this.branca='Cardiologia';
                      break;
      case 'DOPPLERDOUBLE':
                      this.oggetto_json=JSON.stringify(this.doppler_arti_double);
                      obj=this.doppler_arti_double;
                      this.tipo_oggetto='DopplerArtiDouble';
                      this.branca='Cardiologia';
                      break;
      case 'refertazione_privata':
                      this.oggetto_json=JSON.stringify(this.refertazione_privata);
                      obj=this.refertazione_privata;
                      this.tipo_oggetto='refertazione_privata';
                      this.branca='Cardiologia';
                      break;
      case 'eco_addome':
                      this.oggetto_json=JSON.stringify(this.eco_addome);
                      obj=this.eco_addome
                      this.tipo_oggetto='eco_addome';
                      this.branca='Cardiologia';
                      break;
      case 'eco_tiroide':
                      this.oggetto_json=JSON.stringify(this.eco_tiroide);
                      obj=this.eco_tiroide
                      this.tipo_oggetto='eco_tiroide';
                      this.branca='Cardiologia';
                      break;
      case 'PRI':
                      this.oggetto_json=JSON.stringify(this.scheda);
                      obj=this.scheda;
                      this.tipo_oggetto='PRI';
                      this.branca='Fisiatria';                      
                      if(this.creare_scheda) this.SaveForm();
                      break;
      case 'visita_ortopedica':
                      this.oggetto_json=JSON.stringify(this.visita_ortopedica);
                      obj=this.visita_ortopedica;
                      this.tipo_oggetto='visita_ortopedica';
                      this.branca='Fisiatria';             
                      break;
      case 'TestSforzo':
                      this.oggetto_json=JSON.stringify(this.ecg_sforzo);
                      obj=this.ecg_sforzo;
                      this.tipo_oggetto='TestSforzo';
                      this.branca='Cardiologia';
                      break;
      case 'BPE':
                      this.oggetto_json=JSON.stringify(this.bpe);
                      obj=this.bpe;
                      this.tipo_oggetto='BPE';
                      this.branca='Fisiatria';
                      break;
      case 'EsitoInail':
                      this.oggetto_json=JSON.stringify(this.esitoinail);
                      obj=this.esitoinail;
                      this.tipo_oggetto='EsitoInail';
                      this.branca='Fisiatria';
                      break;
      case 'VisitaPneumo':
                      if(this.priorita==2) this.tipo_oggetto='Visita_di_controllo';
                      else if(this.priorita==3) this.tipo_oggetto='Visita_privata';
                      else if(this.priorita==4) this.tipo_oggetto='Visita_+_Sp.Globale';
                      else if(this.priorita==5) this.tipo_oggetto='Visita_+_Sp.Completa';
                      else if(this.priorita==6) this.tipo_oggetto='Visita_+_Sp.Semplice';
                      else if(this.priorita==7) this.tipo_oggetto='Visita_+_Sp.Basale_con_Broncodilatazione';
                      else if(this.priorita==8) this.tipo_oggetto='Sp.Globale';
                      else if(this.priorita==9) this.tipo_oggetto='Sp.Semplice';
                      else if(this.priorita=='a') this.tipo_oggetto='Sp.Basale_con_Broncodilatazione';
                      else if(this.priorita=='b') this.tipo_oggetto='Sp.Completa';
                      else if(this.priorita=='c') this.tipo_oggetto='Controllo_con_Sp.Semplice';
                      else if(this.priorita=='d') this.tipo_oggetto='Controllo_con_Sp.Globale';
                      else if(this.priorita=='e') this.tipo_oggetto='Controllo_con_Sp.Completa';
                      else if(this.priorita=='f') this.tipo_oggetto='Controllo_con_Sp.Basale_con_Broncodilatazione';
                      else if(this.priorita=='g') this.tipo_oggetto='Polisonnografia';
                      else if(this.priorita=='h') this.tipo_oggetto='Sp.Globale_e_Broncodilatazione';
                      else if(this.priorita=='i') this.tipo_oggetto='Sp.Semplice_e_Broncodilatazione';
                      else if(this.priorita=='y') this.tipo_oggetto='dlco';
                      else this.tipo_oggetto='VisitaPneumo';
                      this.vp.visitaP=this.visitaP;
                      this.vp.visita="";
                      if(this.visitaP.precedente && this.visitaP.stampa) this.vp.visita+=this.visitaP.precedente+'\n';
                      if(this.visitaP.anamnesi)this.vp.visita+="Anamnesi: "+this.visitaP.anamnesi+'\n';
                      if(this.visitaP.eo)this.vp.visita+="E.O. Toracico: "+this.visitaP.eo+'\n';
                      if(this.visitaP.diagnosi)this.vp.visita+="Diagnosi: "+this.visitaP.diagnosi+'\n';
                      if(this.visitaP.prescrizioni_farmaceutiche)this.vp.visita+="Prescrizioni farmaceutiche: "+this.visitaP.prescrizioni_farmaceutiche+'\n';
                      if(this.visitaP.prescrizioni_altro)this.vp.visita+="Altre Prescrizioni: "+this.visitaP.prescrizioni_altro+'\n';
                      if(this.visitaP.controllo)this.vp.visita+="Controllo: "+this.visitaP.controllo;
                      this.oggetto_json=JSON.stringify(this.vp);
                      obj=this.vp;
                      this.branca='Pneumologia';
                      break;
      case 'PRPN':
                      this.oggetto_json=JSON.stringify(this.prpn);
                      obj=this.prpn;
                      this.tipo_oggetto='PRPN';
                      this.branca='Pneumologia';
                      break;
      case 'PrickTest':
                      this.prick.set=this.set_pr;
                      this.oggetto_json=JSON.stringify(this.prick);
                      obj=this.prick;
                      this.tipo_oggetto='PrickTest';
                      this.branca='Pneumologia';
                      break;
      case 'TestCammino':
                        this.testC.eta=this.ecg_sforzo.eta;
                        this.testC.bpm_max=Math.floor((208-(this.testC.eta * 0.7))*100.00)/100.00;
                        if(this.testC.sesso=='M') this.testC.percorrenza_teorica=Math.floor(((7.57*this.testC.altezza)-(5.02*this.testC.eta)-(1.76*this.testC.peso)-309)*100.00)/100.00;
                        else this.testC.percorrenza_teorica=Math.floor(((2.11*this.testC.altezza)-(5.88*this.testC.eta)-(2.29*this.testC.peso)+667)*100.00)/100.00;
                        this.testC.bpm_perc=Math.floor(((this.testC.bpm_post/this.testC.bpm_max)*100)*100.00)/100.00;
                        this.testC.BMI=Math.floor(((this.testC.peso*10000)/(this.testC.altezza*this.testC.altezza))*100.00)/100.00;
                        obj=this.testC;
                        this.oggetto_json=JSON.stringify(this.testC);
                        this.tipo_oggetto='TestCammino';
                        this.branca='Pneumologia';
                        break;
      case 'TestCamminoCDCP':
                        this.testC.eta=this.ecg_sforzo.eta;
                        this.testC.bpm_max=Math.floor((208-(this.testC.eta * 0.7))*100.00)/100.00;
                        if(this.testC.sesso=='M') this.testC.percorrenza_teorica=Math.floor(((7.57*this.testC.altezza)-(5.02*this.testC.eta)-(1.76*this.testC.peso)-309)*100.00)/100.00;
                        else this.testC.percorrenza_teorica=Math.floor(((2.11*this.testC.altezza)-(5.88*this.testC.eta)-(2.29*this.testC.peso)+667)*100.00)/100.00;
                        this.testC.bpm_perc=Math.floor(((this.testC.bpm_post/this.testC.bpm_max)*100)*100.00)/100.00;
                        this.testC.BMI=Math.floor(((this.testC.peso*10000)/(this.testC.altezza*this.testC.altezza))*100.00)/100.00;
                        obj=this.testC;
                        this.oggetto_json=JSON.stringify(this.testC);
                        this.tipo_oggetto='TestCamminoCDCP';
                        this.branca='Cardiologia';
                        break;
    }
    this.service.post_function(this.oggetto_json, this.service.apiPostVisite+this.branca+'/'+this.tipo_oggetto+'/'+this.paziente.idscheda).subscribe(
      data=>{
        if(this.tipo_oggetto=='PRPN') this.modificato=0;
        else this.modificato=1;
        let imp;
        switch(this.branca){
          case 'Fisiatria':
          imp=this.impostazioni_cs;
          break;
          case 'Pneumologia':
          imp=this.impostazioni_m2;
          break;
          case 'Cardiologia':
          imp=this.impostazioni_cdcp;
          break;
        }
        this.service.print(tipo,obj,this.today,this.paziente,this.nominativo,this.anamnesi,this.branca,1,imp);
        this.notifiche_bottom.open("Operazione effettuata correttamente", 'OK', { duration: 2500 });
      }
    )

  }

aggiungi_riga_terapia(tipo){
    let temp= new Terapia_visita;
    temp.in_scheda=true;
    temp.id_segmento='1';
    if(tipo=='lea') 
    { 
      temp.id_terapia='1';
      temp.terapia=this.filtro(this.terapie_asl,temp.id_terapia);
    }
    else 
    {
      temp.id_terapia='4';
      temp.terapia=this.filtro(this.terapies,temp.id_terapia);
    }
    temp.segmento=this.filtro_s(this.segmenti,temp.id_segmento);
    temp.quantita_prescritta='1';
    if(tipo=='lea') this.scheda.terapie_asl.push(temp);
    else this.scheda.terapie.push(temp);
}

filtro(temp,id)
{
  
  for(var i=0; i<temp.length; i++) {
    if(temp[i].idterapia==id) return temp[i].descrizione;
  }
}

filtro_s(temp,id)
{
  
  for(var i=0; i<temp.length; i++) {
    if(temp[i].IdSegmento==id) return temp[i].descrizione;
  }
}

AggiornaArray(tipo,i){
  if(tipo=='lea') 
    { 
      this.scheda.terapie_asl[i].terapia=this.filtro(this.terapie_asl,this.scheda.terapie_asl[i].id_terapia);
    }
    else 
    {
      this.scheda.terapie[i].terapia=this.filtro(this.terapies,this.scheda.terapie[i].id_terapia);
    }
}

AggiornaSegmento(tipo,i){
  if(tipo=='lea') 
    { 
      this.scheda.terapie_asl[i].segmento=this.filtro_s(this.segmenti,this.scheda.terapie_asl[i].id_segmento);
    }
    else 
    {
      this.scheda.terapie[i].segmento=this.filtro_s(this.segmenti,this.scheda.terapie[i].id_segmento);
    }
}

AggiungiNote(valore){
 if(this.scheda.note) this.scheda.note+=valore.value+' - ';
 else this.scheda.note=valore.value+' - ';
}

chgRadio(){
this.visitaP.nota99.cat_totale=this.getType(this.visitaP.nota99.cat1)+this.getType(this.visitaP.nota99.cat2)+this.getType(this.visitaP.nota99.cat3)+this.getType(this.visitaP.nota99.cat4)+this.getType(this.visitaP.nota99.cat5)+this.getType(this.visitaP.nota99.cat6)+this.getType(this.visitaP.nota99.cat7)+this.getType(this.visitaP.nota99.cat8);
}

getType(valore)
{
  if(typeof valore === 'number') return valore;
  else return parseInt(valore);
}

Rimuoviterapia(tipo,i){
  if(tipo=='lia') this.scheda.terapie.splice(i,1);
  else this.scheda.terapie_asl.splice(i,1);
}

SaveForm(){ 
  if(typeof this.paziente.data_nascita === "string") {}
  else this.paziente.data_nascita.setHours(2);
  this.union.paziente=this.paziente;
  this.scheda_union.diagnosi=this.scheda.diagnosi;
  this.scheda_union.note_operative=this.scheda.note_operative;
  this.scheda_union.note=this.scheda.note;
  for(var i=0; i<this.scheda.terapie_asl.length; i++) {
    if(this.scheda.terapie_asl[i].in_scheda)
    {
      let temp= new Terapia;
      temp.id_terapia=this.scheda.terapie_asl[i].id_terapia;
      temp.id_segmento=this.scheda.terapie_asl[i].id_segmento;
      temp.quantita_prescritta=this.scheda.terapie_asl[i].quantita_prescritta;
      this.scheda_union.terapie.push(temp);
    }
  }
  for(var i=0; i<this.scheda.terapie.length; i++) {
    if(this.scheda.terapie[i].in_scheda)
    {
      let temp= new Terapia;
      temp.id_terapia=this.scheda.terapie[i].id_terapia;
      temp.id_segmento=this.scheda.terapie[i].id_segmento;
      temp.quantita_prescritta=this.scheda.terapie[i].quantita_prescritta;
      this.scheda_union.terapie.push(temp);
    }
  }
  this.union.scheda=this.scheda_union;
  this.service.post_function(JSON.stringify(this.union),this.service.apiUpdateScheda).subscribe(
    status => {
      this.notifiche_bottom.open("Operazione effettuata correttamente", 'OK', { duration: 2500 });
    }
  );
}

 /* print(tipo,branca): void {
    let printContents, popupWin;
    printContents = document.getElementById(branca).innerHTML+document.getElementById(tipo).innerHTML;//.replace(/(?:\r\n|\r|\n)/g, '<br>') .replace(/(\\r)?\\n/g, '<br />');
    popupWin = window.open('', '_blank', 'top=0,left=0,height=768,width=842');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <style>
          .titolo_cdcp{
            color: #0000c8;
            font-weight: 700;
            font-size: 22px;
            padding-bottom: 10px;
          }

          .visita{
            white-space: pre-wrap
          }

          .informazioni
            {
                font-size: 11px;
                font-style: italic;
            }

            .informazioni_cs
            {
                font-size: 9px;
                font-style: italic;
                text-align: center;
            }

          .anagrafica
          {
            font-weight: 600;
            font-size: 20px;
            letter-spacing: 3px;
            padding-left: 10px;
            padding-right: 10px;
          }

          .visita_cs{
            text-align:center
          }

          .titolo
          {
            font-weight: 600;
            font-size: 18px;
          }

          .anamnesi{
            text-align: center;
            padding-top: 10px;
            fotn-size: 9px;
          }

          .table_vf{
            width: 100%;
            border: 1px solid #000000
        }
        
        .table_vf_laterale{
            writing-mode: vertical-rl;
            text-orientation: upright;
            font-weight: 700;
            font-size: 20px;
            border: 1px solid #000000;
            padding-right: 5px;            
            padding-top: 5px;
            padding-bottom: 5px;
            letter-spacing: 7px;
        }

        .informativa{
          text-align: justify;
        }

        .quantita_prescritta
                {
                  text-align:center;
                }
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
}*/

DP(val){
  if(val=='base') this.ecg_sforzo.doppio_prodotto_base=this.ecg_sforzo.frequenza_base*this.ecg_sforzo.pressione_max_base;
  else this.ecg_sforzo.doppio_prodotto=this.ecg_sforzo.frequenza*this.ecg_sforzo.pressione_max;
}

}

export class Union{
  paziente: Pazienti_schede;
  scheda: Scheda;

}

export class pneumo{
  precedente;
  diagnosi;
  eo;
  prescrizioni_farmaceutiche;
  prescrizioni_altro;
  controllo;
  stampa;
  anamnesi;
  spirometria;
  nota99= new Nota99();
}

export class Nota99{
  diagnosi;
  tiffenau;
  fev1;
  fev1misurato;
  terapia_in_atto;
  descrizione_terapia_in_atto;
  valutazione_clinica;
  proposta_terapia;
  descrizione_proposta_terapia;
  test1=1;
  test2=2;
  test3;
  mmrc;
  cat1=0;
  cat2=0;
  cat3=0;
  cat4=0;
  cat5=0;
  cat6=0;
  cat7=0;
  cat8=0;
  cat_totale=0;
  prima_prescrizione;
  prosecuzione_terapia;
  posologia;
  descrizione_posologia;
  validita='12';
}