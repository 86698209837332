export class Visita_Cardiologica{
    altezza: number;
    peso: number;
    pa1: number;
    pa2: number;
    fumatore: number;
    ex_fumatore: number;
    ipertensione_arteriosa: number;
    diabete: number;
    dislipidemie: number;
    precedenti_diagnosi: Text;
    farmaci_assunti: Text;
    motivo_visita: Text;
    esame_obiettivo: Text;
    prescrizioni: Text;
    ecg: Text;
    data: Date;
    medico: string;
}

export class Prescrizione{
    nome: string;
    cicli: string;
    sedute: string;
}

export class Visita_Fisiatrica{
    diagnosi: Text;
    lea: Array<Prescrizione>;
    lia: Array<Prescrizione>;
    altre_prescrizioni: Text;
    data: Date;
    medico: string;
}


export class EcoD{
    mitrale: string;
    aorta: string;
    atrio_sinistro: string;
    ventricolo_sinistro: string;
    cuore_destro: string;
    doppler: string;
    misure_volumetriche: string;
    note: string;
    conclusioni: string;
  }
  
  export class VisitaC{
    motivo;
    esameo;
    ecg;
    note;
    diagnosi;
    prescrizioni;
    data_prossimo;
  }
  
  export class Anamnesi{
    altezza;
    peso;
    pa;
    fumatore;
    ipertensione_arteriosa;
    diabete;
    dislipidemia;
    precedenti_diagnosi;
    farmaci_assunti;
  }
  

  
  export class ECG_SFORZO{
    eta;
    terapia;
    frequenza_base;
    pressione_max_base;
    pressione_min_base;
    doppio_prodotto_base;
    frequenza;
    pressione_max;
    pressione_min;
    doppio_prodotto;
    interrotto;
    motivo;
    sintomi_soggettivi;
    modificazioni_st;
    aritmie;
    turbe_conduzione;
    conclusioni;
  
  }

  export class BPE{
    stabilometrico;
    statica;
    dinamica;
    biometria;
    posturografico;
    precedente;
    consigli;

    constructor(){
      this.consigli="L'uso di plantari propriocettivi personalizzati e controllo fra un anno.";
    }
  }

  export class pt_element{
    name;
    display_name;
    group;
    set;
    value;
  }

  export class pricktest{
    pt_elements= new Array<pt_element>()
    negativo;
    positivo;
    conclusioni;
    set;

    constructor(){
      this.negativo=this.positivo=0;
      this.conclusioni="";
      this.set=1;
      this.pt_elements=[
        {name: 'graminacee',display_name:'Mix graminacee',group:1,set:1,value: 0},
        {name: 'farinae',display_name:'Dermatophagoides pt e farinae',group:1,set:1,value: 0},
        {name: 'composite',display_name:'Mix composite',group:1,set:1,value: 0},
        {name: 'cane',display_name:'Epitelio cane',group:1,set:1,value: 0},
        {name: 'gatto',display_name:'Epitelio gatto',group:1,set:1,value: 0},
        {name: 'olivo',display_name:'Olivo',group:1,set:1,value: 0},
        {name: 'parietaria',display_name:'Parietaria',group:1,set:1,value: 0},
        {name: 'cupressacee',display_name:'Mix cupressacee',group:1,set:1,value: 0},
        {name: 'latte',display_name:'Latte vaccino',group:2,set:1,value: 0},
        {name: 'arachidi',display_name:'Arachidi',group:2,set:1,value: 0},
        {name: 'caseina',display_name:'Caseina di Mucca',group:2,set:1,value: 0},
        {name: 'lattoalbumina',display_name:'a-lattoalbumina',group:2,set:1,value: 0},
        {name: 'mela',display_name:'Mela',group:2,set:1,value: 0},
        {name: 'pesca',display_name:'Pesca',group:2,set:1,value: 0},
        {name: 'pomodoro',display_name:'Pomodoro',group:2,set:1,value: 0},
        {name: 'uovo',display_name:'Uovo Albume',group:2,set:1,value: 0},
        {name: 'frumento',display_name:'Frumento Farina',group:2,set:1,value: 0},
        {name: 'mais',display_name:'Mais Farina',group:2,set:1,value: 0},
        {name: 'graminacee',display_name:'Mix graminacee',group:1,set:2,value: 0},
        {name: 'farinae',display_name:'Dermatophagoides pt e farinae',group:1,set:2,value: 0},
        {name: 'composite',display_name:'Mix composite',group:1,set:2,value: 0},
        {name: 'cane',display_name:'Epitelio cane',group:1,set:2,value: 0},
        {name: 'gatto',display_name:'Epitelio gatto',group:1,set:2,value: 0},
        {name: 'olivo',display_name:'Olivo',group:1,set:2,value: 0},
        {name: 'parietaria',display_name:'Parietaria',group:1,set:2,value: 0},
        {name: 'cupressacee',display_name:'Mix cupressacee',group:1,set:2,value: 0},
        {name: 'latte',display_name:'Latte Vaccino',group:2,set:2,value: 0},
        {name: 'nocciola',display_name:'Nocciola',group:2,set:2,value: 0},
        {name: 'farina',display_name:'Farina Bianca',group:2,set:2,value: 0},
        {name: 'merluzzo',display_name:'Merluzzo',group:2,set:2,value: 0},
        {name: 'uovo',display_name:'Uovo',group:2,set:2,value: 0},
        {name: 'sei',display_name:' ',group:2,set:2,value: 0},
        {name: 'sette',display_name:' ',group:2,set:2,value: 0},
        {name: 'otto',display_name:' ',group:2,set:2,value: 0},
      ];
    }
  }

  export class Test_Cammino{
    sesso;
    altezza;
    eta;
    peso;
    BMI;
    fi;
    bpm_max;
    percorrenza_teorica;
    percorrenza_misurata;
    sat_pre;
    sat_post;
    bpm_pre;
    bpm_post;
    bpm_perc;
    borg_pre;
    borg_post;
    recupero;
    note;

    constructor(){
      this.sesso="M"
      this.fi=0.21;
    }
  }

  export class PRPN{
    prescrizione1;
    quantita1;
    nota1;
    si1;
    prescrizione2;
    quantita2;
    nota2;
    si2;
    prescrizione3;
    quantita3;
    nota3;
    si3;
    prescrizione_farmaceutica;
    altre_prescrizioni;
    diagnosi;
    cicli;

    constructor(){
      this.prescrizione1="93181 (51441) - RIEDUCAZIONE MOTORIA CARDIO RESPIRATORIA INDIVIDUALE \n------------------ \n93993 (51508) - DRENAGGIO DELLE SECREZIONI BRONCHIALI"
      this.quantita1="1\n----\n2";
      this.si1=true;
      this.prescrizione2="89655 (49761) - MONITORAGGIO INCRUENTO DELLA SATURAZIONE ARTERIOSA ";
      this.quantita2="8";
      this.si2=true;
      this.prescrizione3="93117 (50956) - ADESTRAMENTO ALLA RESPIRAZIONE DIAFRAMMATICA, ESERCIZI CALISTENICI";
      this.quantita3="2";
      this.si3=false;
      this.cicli="6";
    }
  }

  export class VP{
    riferimento;
    professione;
    visita;
    visitaP;
    trattamenti= [
      new trattamento('Visita',0),
      new trattamento('Aerosolterapia',0),
      new trattamento('Ventiloterapia',0),
      new trattamento('Ginnastica Respiratoria',0),
      new trattamento('Ginnastica Correttiva',0),
      new trattamento('Ginnastica Ostetrica',0),
      new trattamento('Riabilitazione Neur/Ortop',0),
      new trattamento('Medicina Estetica',0),
      new trattamento('Diete computerizzate',0),
      new trattamento('Terapia Strumentale',0),
      new trattamento('Terapia Farmacologica',0),
      new trattamento('T.I.S.',0),
      new trattamento('13',0),
      new trattamento('14',0),
      new trattamento('15',0),
      new trattamento('Visita Pneumologica',0),
      new trattamento('Visita Fisiatrica',0),
    ];
  }

  export class trattamento{
    nome;
    valore;

    constructor(nom,val){
      this.nome=nom;
      this.valore=val;
    }
  }

 export const array_nomi= new Array(
    'visita_ecg',
    'controllo',
    'controllo_ecg',
    'ecg',
    'visita_priority',
    'prima_visita_priority',
    'ecocardiogramma',
    'tsa',
    'doppler',
    'test_sforzo',
    'visita_p',
    'controllo_p',
    'visita_privata',
    'spiro_semplice',
    'spiro_globale',
    'spiro_basale',
    'spiro_completa',
    'visita_spiro_semplice',
    'visita_spiro_globale',
    'visita_spiro_basale',
    'visita_spiro_completa',
    'controllo_spiro_semplice',
    'controllo_spiro_globale',
    'controllo_spiro_basale',
    'controllo_spiro_completa',
    'test_cammino',
    'polisonnografia',
    'prick_test',
    'prpn',
    'pri',
    'bpe',
    'esito_inail'
 );

 export const array_nomi_cardiologia= new Array(
  'visita_ecg',
  'controllo',
  'controllo_ecg',
  'ecg',
  'visita_priority',
  'prima_visita_priority',
  'ecocardiogramma',
  'tsa',
  'doppler',
  'test_sforzo'
 );

 export const array_etichette_cardiologia= new Array(
  'Visita',
  'Visita di controllo',
  'Controllo con ECG',
  'Solo ECG',
  'Visita Priority',
  'Prima Visita Priority',
  'Ecocardiogramma',
  'TSA',
  'Doppler arti',
  'Test da Sforzo'
);

 export const array_nomi_pneumologia= new Array(
  'visita_p',
  'controllo_p',
  'visita_privata',
  'spiro_semplice',
  'spiro_globale',
  'spiro_basale',
  'spiro_completa',
  'visita_spiro_semplice',
  'visita_spiro_globale',
  'visita_spiro_basale',
  'visita_spiro_completa',
  'controllo_spiro_semplice',
  'controllo_spiro_globale',
  'controllo_spiro_basale',
  'controllo_spiro_completa',
  'test_cammino',
  'polisonnografia',
  'prick_test',
  'prpn'
 );

 export const array_etichette_pneumologia= new Array(
   'Visita',
   'Visita di controllo',
   'Visita privata',
   'Spirometria Semplice',
   'Spirometria Globale',
   'Spirometria Basale con Broncodilatazione',
   'Spirometria Completa',
   'Visita e Spirometria Semplice',
   'Visita e Spirometria Globale',
   'Visita e Spirometria Basale con Broncodilatazione',
   'Visita e Spirometria Completa',
   'Controllo e Spirometria Semplice',
   'Controllo e Spirometria Globale',
   'Controllo e Spirometria Basale con Broncodilatazione',
   'Controllo e Spirometria Completa',
   'Test del Cammino',
   'Polisonnografia',
   'Prick Test',
   'Prescrizione Fisioterapia'
 );

 export const array_nomi_fisiatria= new Array(
  'pri',
  'bpe',
  'esito_inail'
 );


import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'newline'})
export class NewlinePipe implements PipeTransform {
  transform(value: string, args: string[]): any {
    if (!value) return value;
    return value.replace(/(\\r)?\\n/g, '<br/>');
  }
}

export class TipiVisite{
  id;
  tipo_visita;
  descrizione;
  strutture_abilitate;
  cctrl;
  costo;
  branca;
}
